import React from 'react';
import styles from './UserPhotoEdit.module.css';
import useForm from '../../Hooks/useForm';
import useFetch from '../../Hooks/useFetch';
import Input from '../Forms/Input';
import TextArea from '../Forms/TextArea';
import Select from '../Forms/select';
import Button from '../Forms/Button';
import Erro from '../Helper/Error';
import Erros from '../Helper/Erros';
import { SITE_UPDATE, SITE_GET } from '../../Api';
import { useNavigate } from 'react-router-dom';
import Head from '../Helper/Head';
import canvasSketch from 'canvas-sketch'
import math from 'canvas-sketch-util/math'
import random from 'canvas-sketch-util/random'
import axios from 'axios';
import Loading from '../Helper/Loading2';
import Login from '../Panel/Login';
import {ProgressBar} from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { photoPost } from '../../store/photoPost';
import { API_URL } from '../../config/App';

const UserSiteEdit = (props) => {
  const nome = useForm();
  const corpo = useForm();

  const html = useForm();
  const css = useForm();
  const js = useForm();
  const header = useForm();
  const footer = useForm();

  const link = useForm();
  const video = useForm();
  const shared = useForm('number');
  const category = useForm('number');
  const status = useForm('number');
  const visible = useForm('number');
  const priority = useForm('number');
  const [letter, setLetter] = React.useState('X');
  const [img, setImg] = React.useState({});
  const [eye, setEye] = React.useState('none');
  const [doc, setDoc] = React.useState(null);
  const [canva, setCanva] = React.useState('block');
  //const [blob, setBlob] = React.useState('');
  const [editor, setEditor] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const { data, loading, request, response } = useFetch();
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = React.useState([])
  const [progress, setProgress] = React.useState(0)

  var context = '';
  var canvas = '';
  var imgx = '';
  var imgtemp = '';
  var resultado = '';
  var dataurl = '';
  var blob = '';
  var myTimeout = '';
  var text = '';

  const array_shared = [
    {name: 'Público', value: 1}, 
    {name: 'Privado', value: 0}
  ]

  var array_categories = [];

  const array_status = [
    {name: 'Apenas uma postagem', value: 1}, 
    {name: 'Trabalhando nisso', value: 2},
    {name: 'Concluído', value: 3},
  ]

  const array_priority = [
    {name: '1', value: 1}, 
    {name: '2', value: 2},
    {name: '3', value: 3},
    {name: '4', value: 4}
  ]

  const array_visible = [
    {name: 'Visivel', value: 1}, 
    {name: 'Invisível', value: 0}
  ]
  
  const params = {
    cols: 10,
    rows: 10,
    scaleMin: 1,
    scaleMax: 30,
    freq: 0.001,
    amp: 0.2,
    frame: 0,
    animate: true,
    lineCap: 'butt',
};

  React.useEffect(() => {
    //setLetter('B');
    status.setValue(1);
    visible.setValue(1);
    priority.setValue(1);
    shared.setValue(1); 
    category.setValue(1);   
    gerarArte();
    getCategories();
    getById();
    //setTimeout(gerarArte, 3000);
    //myTimeout = setTimeout(myStopFunction, 3000);

    var myEditor;

    window.ClassicEditor.create( document.querySelector( '#editor' ), {
      
      licenseKey: '',
    
      link: {
          decorators: {
            toggleDownloadable: {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'file'
              }
            },
            openInNewTab: {
              mode: 'manual',
              label: 'Open in a new tab',
              defaultValue: true,			// This option will be selected by default.
              attributes: {
                target: '_blank',
                rel: 'noopener noreferrer'
              }
            }
          }
        },
      htmlEmbed: {
        showPreviews: true,
      }  
    
      
    } )
    .then( editor => {
      window.editor = editor;
      myEditor = editor;
      // Set a custom container for the toolbar.
       //  document.querySelector( '.document-editor__toolbar' ).appendChild( editor.ui.view.toolbar.element );
      //   document.querySelector( '.ck-toolbar' ).classList.add( 'ck-reset_all' );
    } )
    .catch( error => {
      console.error( 'Oops, something went wrong!' );
      console.error( 'Please, report the following error on https://github.com/ckeditor/ckeditor5/issues with the build id and the error stack trace:' );
      console.warn( 'Build id: 9i9i9hewfymf-ak8ig74wt7az' );
      console.error( error );
    } );
    

  }, []);

  async function getById(){
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
    const { url, options } = SITE_GET(id || props.id);
    const { response, json } = await request(url, options);

    console.log(json);

    nome.setValue(json.photo.title);
 
    if (json.photo.html != null) html.setValue(json.photo.html);
    if (json.photo.css != null) css.setValue(json.photo.css);
    if (json.photo.js != null) js.setValue(json.photo.js);
    if (json.photo.header != null) header.setValue(json.photo.header);
    if (json.photo.footer != null) footer.setValue(json.photo.footer);

    if (json.photo.body != null) setEditor(json.photo.body);
    if (json.photo.body != null) window.editor.setData(json.photo.body);
    if (json.photo.link != null) link.setValue(json.photo.link);
    if (json.photo.video != null) video.setValue(json.photo.video);
    if (json.photo.doc != null) setDoc(json.photo.doc);
 
    shared.setValue(json.photo.shared);
    if (json.categories.length != 0) category.setValue(json.categories[0].id);
    status.setValue(json.photo.status);
    visible.setValue(json.photo.visible);
    priority.setValue(json.photo.priority);  

    const eye = document.getElementById('eye');
    const imgatual = document.getElementById('img');
    const sketchatual = document.getElementById('sketchatual');
    sketchatual.style.display = "none";
    eye.style.display = "block";
    imgtemp = new Image();
    imgtemp.src = json.photo.src; 
    imgtemp.onload = function(){
      eye.src = imgtemp.src;    
      imgatual.src = imgtemp.src;
    };


  }

  function getCategories () {
    axios.get(`${API_URL}/getcategories`)
    .then(res => {
      res.data.forEach(element => {
        array_categories.push({'name' : element.name, 'value' : element.id });
      });
      setCategories(array_categories);    
    })
  }
  
  function ahandleOutsideClick(event) {
    console.log('target');
    console.log(event.target);

    console.log('onclose');
    console.log(event.currentTarget);   
    //if (event.target === event.currentTarget) setModalPhoto(null);
 
   // if (event.target === event.currentTarget) dispatch(closeModal());
  }

  function gerarArte() {

    canvas = document.getElementById("sketchatual");
    context = canvas.getContext("2d");
    imgx = document.getElementById("eye");

    const settings = {
      duration: 3,
      scaleToView: true,
      playbackRate: 'throttle',
      animate: false,
      fps: 24,
      amp: 0.2,
      letter: 'E',
      canvas: canvas,
      context: context,
      lineCap: 'butt',
      dimensions: [ 600, 600 ]
    };
  
    
    var variado = Math.floor(Math.random() * 6);
    switch (variado) {
      case 0 : canvasSketch(sketch, settings) 
      break;
      case 1 : canvasSketch(sketch2, settings)
      break;
      case 2 : canvasSketch(sketch3, settings)
      break;
      case 3 : canvasSketch(sketch4, settings)
      break;
      case 4 : canvasSketch(sketch5, settings)
      break;
      case 5 : canvasSketch(sketch6, settings)
      break;
      default:
        canvasSketch(sketch, settings)
    }
    gravarimg();
    //setTimeout(gravarimg, 2000);
  }

  function gravarimg() {
    dataurl = canvas.toDataURL();
  
    imgtemp = new Image();

    //imgx.src = dataurl;
    imgtemp.src = dataurl;
 
    imgtemp.onload = function(){
      //imgx.src = dataurl;
    };

      var base64ImageContent = imgtemp.src.replace(/^data:image\/(png|jpg);base64,/, "");
      blob = base64ToBlob(base64ImageContent, 'image/png'); 
      
      //console.log('dataurl');
      //console.log(dataurl);

      //console.log('blob');
      //console.log(blob);
  }

  async function handleSubmit(event) {
   
    event.preventDefault();
  
      setError(null);
      const formData = new FormData();
      const pdf = document.getElementById('pdf');
      const imgatualx = document.getElementById('img');
      
      if (img.raw) 
      {
        formData.append('img', img.raw)
        console.log(img.raw);    
      }       

      formData.append('pdf', pdf.files[0]);
      formData.append('nome', nome.value);
      formData.append('html', html.value);
      formData.append('css', css.value);
      formData.append('js', js.value);
      formData.append('header', header.value);
      formData.append('footer', footer.value);
      formData.append('corpo', window.editor.getData());
      formData.append('link', link.value);
      formData.append('video', video.value);
      formData.append('status', status.value);
      formData.append('shared', shared.value);
      formData.append('category', category.value);
      formData.append('visible', visible.value);
      formData.append('priority', priority.value);
  

      const params = new URLSearchParams(window.location.search);
      const paramValue = params.get("id") || props.id;

      //const token = window.localStorage.getItem('token');
      //const { url, options } = PHOTO_UPDATE(formData, paramValue);
      
      var fecha = this;

      axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

      axios ({
        method: 'POST',
        url: `${API_URL}/club/update/site/${paramValue}`,
        data: formData,
        headers: {
          'Accept' : 'application/json',
          Authorization: 'Bearer ' + window.localStorage.getItem('token'),
        }, 
        onUploadProgress: data => {
           let temp = (Math.round((100 * data.loaded) / data.total));
           //console.log(Math.round((100 * data.loaded) / data.total));
           setProgress(temp);
          },
        transformRequest: [(data, headers) => {
            return data;
        }]
    }).then(function (response) {

      props.fecharItem();
        // fecha.props.fecharItem(null);
        //navigate('/courses/ideas');
        //setProgress('');

    }).catch(error => {
      console.log (error);
      const { code } = error?.response?.data
      switch (code) {
        case "FILE_MISSING":
          setError("Selecione um arquivo antes de enviar!")
          break
        case "413":
          setError("Arquivo grande demais!")
          break
        default:
          setError(error.response);
          break
      }
    });

      //const { response, json } = await request(url, options);
  
  }

  async function handleSubmitjjjj(event) {
   
    event.preventDefault();
    try {
  
      setError(null);
      const formData = new FormData();
      const pdf = document.getElementById('pdf');
      const imgatualx = document.getElementById('img');
      
      if (img.raw) 
      {
        formData.append('img', img.raw)
        console.log(img.raw);    
      }       
      else 
      {
      //setLetter(nome.value.charAt(0));
      //gerarArte();      
      //console.log(blob);
      //formData.append('img', blob)
      };

      formData.append('pdf', pdf.files[0]);
      formData.append('nome', nome.value);
      formData.append('corpo', corpo.value);
      formData.append('link', link.value);
      formData.append('video', video.value);
      formData.append('status', status.value);
      formData.append('shared', shared.value);
      formData.append('category', category.value);
      formData.append('visible', visible.value);
      formData.append('priority', priority.value);
  

      const params = new URLSearchParams(window.location.search);
      const paramValue = params.get("id");

      const token = window.localStorage.getItem('token');
      const { url, options } = SITE_UPDATE(formData, paramValue);
      
      
      //const tokenRes = await request(url, options);
      const { response, json } = await request(url, options);

        console.log(json);

      if (response.ok) {
        //navigate('/courses/ideas');
        console.log('ok');
        //if (data) navigate('/conta');
      } else {
        setErrors(json.errors);
        throw new Error(json.message);  
      }
  
    } catch (erro) {
      if (erro.message === 'The given data was invalid.') {
        setError('Dados inválidos.');
      } else if (erro.message === 'Token has expired') {
        setError('Você precisa se logar novamente.');
        //setTimeout(userLogout(), 5000);
      } else {
        setError(erro.message);
        //setTimeout(userLogout(), 5000);
      }
    } finally {
      //setLoading(false);
    }

  }


  function someLoader() {
    console.log ('teste');
  }

  const sketch = async () => {
    await someLoader();
    return ({ context, width, height, frame }) => {
        context.fillStyle = 'black';
        context.fillRect(0, 0, width, height);

        const cols = params.cols;
        const rows = params.rows;
        const numCells = cols * rows;

        const gridw = width  * 0.8;
        const gridh = height * 0.8;
        const cellw = gridw / cols;
        const cellh = gridh / rows;
        const margx = (width  - gridw) * 0.5;
        const margy = (height - gridh) * 0.5;

        var theNeuron = Math.floor(Math.random() * 99) + 1;

        for (let i = 0; i < numCells; i++) {
            const col = i % cols;
            const row = Math.floor(i / cols);

            const x = col * cellw;
            const y = row * cellh;
            const w = cellw * 0.8;
            const h = cellh * 0.8;

            const f = params.animate ? frame : params.frame;

            // const n = random.noise2D(x + frame * 10, y, params.freq);
            //let freqx = Math.random() * 3
            const n = random.noise3D(x, y, f * 10, params.freq);

            const angle = n * Math.PI * params.amp;
            
            // const scale = (n + 1) / 2 * 30;
            // const scale = (n * 0.5 + 0.5) * 30;
            const scale = math.mapRange(n, -1, 1, params.scaleMin, params.scaleMax);

            context.save();
            context.translate(x, y);
            context.translate(margx, margy);
            context.translate(cellw * 0.5, cellh * 0.5);
            //context.rotate(angle);

            context.lineWidth = scale;
            context.lineCap = params.lineCap;
          
            context.beginPath();
            //context.arc(100,25,5,0,1.5*Math.PI);
            var theRandomNumber = Math.floor(Math.random() * 3) + 1;
            var angle2=Math.atan2(0,90);

            switch(theRandomNumber) {
                case 1:
                    angle2=Math.atan2(0,90);
                  break;
                case 2:
                    angle2=Math.atan2(0,-90);
                  break;
                case 3:
                    angle2=Math.atan2(90,0);
                  break;
                case 4:
                    angle2=Math.atan2(-90,0);
                  break;
                default:
                    angle2=Math.atan2(-90,0);
              }

            context.arc(1 * -0.5, 1 *  0.5, 23, angle2, angle2 + Math.PI, false);
            //context.arc(1 * -0.5, 1 *  0.5, 10, angle, angle + Math.PI, false);
            if (i == theNeuron)
            context.fillStyle = 'yellow';
            else 
            context.fillStyle = 'green';

            context.fill();
            //context.stroke();

            context.restore();
        }

      };
    };


    const sketch2 = () => {
      return ({ context, width, height }) => {
        context.fillStyle = 'white';
        context.fillRect(0, 0, width, height);
        context.lineWidth = width * 0.01;
    
        const w 	= width  * 0.10;
        const h 	= height * 0.10;
        const gap = width  * 0.03;
        const ix 	= width  * 0.17;
        const iy 	= height * 0.17;
    
        const off = width  * 0.02;
    
        let x, y;
    
        for (let i = 0; i < 5; i++) {
          for (let j = 0; j < 5; j++) {
            x = ix + (w + gap) * i;
            y = iy + (h + gap) * j;
    
            context.beginPath();
            context.rect(x, y, w, h);
            context.stroke();
    
            if (Math.random() > 0.5) {
              context.beginPath();
              context.rect(x + off / 2, y + off / 2, w - off, h - off);
              context.stroke();
            }
          }
        }
      };
    };


    const sketch3 = () => {
      return ({ context, width, height }) => {
        context.fillStyle = 'white';
        context.fillRect(0, 0, width, height);
    
        context.fillStyle = 'black';
    
        const cx = width  * 0.5;
        const cy = height * 0.5;
    
        const w = width  * 0.01;
        const h = height * 0.1;
        let x, y;
    
        const num = 40;
        const radius = width * 0.3;
    
        for (let i = 0; i < num; i++) {
          const slice = math.degToRad(360 / num);
          const angle = slice * i;
    
          x = cx + radius * Math.sin(angle);
          y = cy + radius * Math.cos(angle);
    
          context.save();
          context.translate(x, y);
          context.rotate(-angle);
          context.scale(random.range(0.1, 2), random.range(0.2, 0.5));
    
          context.beginPath();
          context.rect(-w * 0.5, random.range(0, -h * 0.5), w, h);
          context.fill();
          context.restore();
    
          context.save();
          context.translate(cx, cy);
          context.rotate(-angle);
    
          context.lineWidth = random.range(5, 20);
    
          context.beginPath();
          context.arc(0, 0, radius * random.range(0.7, 1.3), slice * random.range(1, -8), slice * random.range(1, 5));
          context.stroke();
    
          context.restore();
        }
      };
    };


    const sketch4 = () => {
      return ({ context, width, height, frame }) => {
        context.fillStyle = 'white';
        context.fillRect(0, 0, width, height);
    
        const cols = params.cols;
        const rows = params.rows;
        const numCells = cols * rows;
    
        const gridw = width  * 0.8;
        const gridh = height * 0.8;
        const cellw = gridw / cols;
        const cellh = gridh / rows;
        const margx = (width  - gridw) * 0.5;
        const margy = (height - gridh) * 0.5;
    
        for (let i = 0; i < numCells; i++) {
          const col = i % cols;
          const row = Math.floor(i / cols);
    
          const x = col * cellw;
          const y = row * cellh;
          const w = cellw * 0.8;
          const h = cellh * 0.8;
    
          const f = params.animate ? frame : params.frame;
    
          // const n = random.noise2D(x + frame * 10, y, params.freq);
          const n = random.noise3D(x, y, f * 10, params.freq);
    
    
          const angle = n * Math.PI * params.amp;
          
          // const scale = (n + 1) / 2 * 30;
          // const scale = (n * 0.5 + 0.5) * 30;
          const scale = math.mapRange(n, -1, 1, params.scaleMin, params.scaleMax);
    
          context.save();
          context.translate(x, y);
          context.translate(margx, margy);
          context.translate(cellw * 0.5, cellh * 0.5);
          context.rotate(angle);
    
          context.lineWidth = scale;
          context.lineCap = params.lineCap;
    
          context.beginPath();
          context.moveTo(w * -0.5, 0);
          context.lineTo(w *  0.5, 0);
          context.stroke();
    
          context.restore();
        }
    
      };
    };
    
    const getGlyph = (v) => {
      if (v < 50) return '';
      if (v < 100) return '.';
      if (v < 150) return '-';
      if (v < 200) return '+';
    
      const glyphs = '_= /'.split('');
    
      return random.pick(glyphs);
    };
    
    let manager;
    const typeCanvas = document.createElement('canvas');
    const typeContext = typeCanvas.getContext('2d');

    ///console.log('texto',letter);
    let fontSize = 1200;
    let fontFamily = 'serif';

    const sketch5 = ({ context, width, height }) => {

      const cell = 20;
      const cols = Math.floor(width  / cell);
      const rows = Math.floor(height / cell);
      const numCells = cols * rows;
    
      typeCanvas.width  = cols;
      typeCanvas.height = rows;
    
      return ({ context, width, height }) => {
        typeContext.fillStyle = 'black';
        typeContext.fillRect(0, 0, cols, rows);
    
        fontSize = cols * 1.2;
    
        typeContext.fillStyle = 'white';
        typeContext.font = `${fontSize}px ${fontFamily}`;
        typeContext.textBaseline = 'top';
    


        const metrics = typeContext.measureText(letter);
        const mx = metrics.actualBoundingBoxLeft * -1;
        const my = metrics.actualBoundingBoxAscent * -1;
        const mw = metrics.actualBoundingBoxLeft + metrics.actualBoundingBoxRight;
        const mh = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
    
        const tx = (cols - mw) * 0.5 - mx;
        const ty = (rows - mh) * 0.5 - my;
    
        typeContext.save();
        typeContext.translate(tx, ty);
    
        typeContext.beginPath();
        typeContext.rect(mx, my, mw, mh);
        typeContext.stroke();
    
        console.log('textoAAAAAAAAAAAAAA',text);

        typeContext.fillText(letter, 0, 0);
        typeContext.restore();
    
        const typeData = typeContext.getImageData(0, 0, cols, rows).data;
    
    
        context.fillStyle = 'black';
        context.fillRect(0, 0, width, height);
    
        context.textBaseline = 'middle';
        context.textAlign = 'center';
    
        // context.drawImage(typeCanvas, 0, 0);
    
        for (let i = 0; i < numCells; i++) {
          const col = i % cols;
          const row = Math.floor(i / cols);
    
          const x = col * cell;
          const y = row * cell;
    
          const r = typeData[i * 4 + 0];
          const g = typeData[i * 4 + 1];
          const b = typeData[i * 4 + 2];
          const a = typeData[i * 4 + 3];
    
          const glyph = getGlyph(r);
    
          context.font = `${cell * 2}px ${fontFamily}`;
          if (Math.random() < 0.1) context.font = `${cell * 6}px ${fontFamily}`;
    
          context.fillStyle = 'white';
    
          context.save();
          context.translate(x, y);
          context.translate(cell * 0.5, cell * 0.5);
    
          // context.fillRect(0, 0, cell, cell);
    
          context.fillText(glyph, 0, 0);
          
          context.restore();
    
        }
      };
    };

    const sketch6 = ({ context, width, height }) => {
      const agents = [];
    
      for (let i = 0; i < 40; i++) {
        const x = random.range(0, width);
        const y = random.range(0, height);
    
        agents.push(new Agent(x, y));
      }
    
      return ({ context, width, height }) => {
        context.fillStyle = 'white';
        context.fillRect(0, 0, width, height);
    
        for (let i = 0; i < agents.length; i++) {
          const agent = agents[i];
    
          for (let j = i + 1; j < agents.length; j++) {
            const other = agents[j];
    
            const dist = agent.pos.getDistance(other.pos);
    
            if (dist > 200) continue;
    
            context.lineWidth = math.mapRange(dist, 0, 200, 12, 1);
    
            context.beginPath();
            context.moveTo(agent.pos.x, agent.pos.y);
            context.lineTo(other.pos.x, other.pos.y);
            context.stroke();
          }
        }
    
        agents.forEach(agent => {
          agent.update();
          agent.draw(context);
          agent.bounce(width, height);
        });
      };
    };
    


  function base64ToBlob(base64, mime) 
  {
      mime = mime || '';
      var sliceSize = 1024;
      var byteChars = window.atob(base64);
      var byteArrays = [];
  
      for (var offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
          var slice = byteChars.slice(offset, offset + sliceSize);
  
          var byteNumbers = new Array(slice.length);
          for (var i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
          }
  
          var byteArray = new Uint8Array(byteNumbers);
  
          byteArrays.push(byteArray);
      }
  
      return new Blob(byteArrays, {type: mime});
  }

  function handleImgChange({ target }) {
    //console.log(target.files[0])
    setEye('block');
    setCanva('none');
    setImg({
      preview: URL.createObjectURL(target.files[0]),
      raw: target.files[0],
    });
  }

  function handleNome({ target }) {
    setLetter(nome.value.charAt(0));
    gerarArte();
    //gravarimg();
  }

  function handleyoutube(e) {
    var value = e.currentTarget.value;
    var url = ''; 
    if (validURL(value)) {
      url = new URL(value);
      if (url.searchParams.has("v")) {
      value = url.searchParams.get("v");
      }
    }
    video.setValue(value);
  }

  function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  const { user, token } = useSelector((state) => state.login);

  if (user.loading || token.loading) return <Loading />;
  //if (user.data)
  return (    
    <section className={`${styles.photoPost} animeLeft`}>

       <canvas id="sketchatual" width="600" height="600" style={{ display : canva}}></canvas>
      {!img.raw && <img id='eye' style={{ display : eye}}/>}


    
      <Head title="Poste sua ideia" />
      <form onSubmit={handleSubmit}>

        <Input id="titulo" label="Titulo" type="text" name="nome" {...nome} />

        <textarea name="body" id="editor" cols="30" rows="10"  className="form-control ck-content"></textarea>
        
        {user.data.points == 1080 && 
        <div> 
        <textarea name="html" cols="30" rows="10" placeholder='Html' style={{marginTop : '10px'}} className="form-control" {...html}></textarea>
        <textarea name="header"  cols="30" rows="10" placeholder='Header' style={{marginTop : '10px'}} className="form-control" {...header}></textarea>
        <textarea name="css"  cols="30" rows="10" placeholder='Css' style={{marginTop : '10px'}} className="form-control" {...css}></textarea>
        <textarea name="footer"  cols="30" rows="10" placeholder='Footer' style={{marginTop : '10px'}} className="form-control" {...footer}></textarea>
        <textarea name="js"  cols="30" rows="10" placeholder='Js' style={{marginTop : '10px'}} className="form-control" {...js}></textarea>
        </div>
        }

        <Input style={{ marginTop : '1rem' }} label="Link de site" type="text" name="link" {...link} />
        <Input label="Vídeo do youtube" onBlur={handleyoutube} type="text" name="video" {...video} />
        <Select label="Status" type="text" name="status" items={array_status} {...status} />
        <Select label="Visibilidade" type="text" name="visible" items={array_visible} {...visible} />
        <Select label="Prioridade" type="text" name="status" items={array_priority} {...priority} />
        <Select label="Compartilhamento" type="text" name="shared" items={array_shared} {...shared} />
        <Select label="Categorias" type="text" name="category" items={categories} {...category} />
        <label>Uma Imagem</label>
        <input
          className={styles.file}
          type="file"
          name="img"
          id="img"
          onChange={handleImgChange}
        />
        <label>Um PDF, TXT, CSV...</label>
        <input
          className={styles.file}
          type="file"
          name="pdf"
          id="pdf"
        />
        {loading ? (
          <Button disabled>Enviando...</Button>
        ) : (
          <Button>Enviar</Button>
        )}
        <Erro error={error} />
        <Erros errors={errors} />
      
      <br/>
      <ProgressBar now={progress} label={`${progress}%`} />

      </form>

      <div>
        {img.preview && (
          <div
            className={styles.preview}
            style={{ backgroundImage: `url('${img.preview}')` }}
          ></div>
        )}
      </div>

    </section>
  );
//  else return <Login />;
};

class Vector {
	constructor(x, y) {
		this.x = x;
		this.y = y;
	}

	getDistance(v) {
		const dx = this.x - v.x;
		const dy = this.y - v.y;
		return Math.sqrt(dx * dx + dy * dy);
	}
}

class Agent {
	constructor(x, y) {
		this.pos = new Vector(x, y);
		this.vel = new Vector(random.range(-1, 1), random.range(-1, 1));
		this.radius = random.range(4, 12);
	}

	bounce(width, height) {
		if (this.pos.x <= 0 || this.pos.x >= width)  this.vel.x *= -1;
		if (this.pos.y <= 0 || this.pos.y >= height) this.vel.y *= -1;
	}

	update() {
		this.pos.x += this.vel.x;
		this.pos.y += this.vel.y;
	}

	draw(context) {
		context.save();
		context.translate(this.pos.x, this.pos.y);

		context.lineWidth = 4;

		context.beginPath();
		context.arc(0, 0, this.radius, 0, Math.PI * 2);
		context.fill();
		context.stroke();

		context.restore();
	}
}

export default UserSiteEdit;
