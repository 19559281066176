import axios from 'axios'
import swal from 'sweetalert';
import { autoLogin } from '../store/login';
import { reduxForm, Field, initialize, arrayInsert, arrayRemove, formValueSelector } from 'redux-form'
import { API_URL } from '../config/App';

export function updateAvatar(values) {
    return submitAvatar(values, 'post')
}

export function updateUser(values) {
    return submitUser(values, 'post')
}

export function getData2() {
    return dispatch => {
        axios.get(`${API_URL}/club/user`,{
            headers: {
              'Authorization': 'Bearer ' + window.localStorage.getItem('token')
            }
          })
            .then(resp => {
                //toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                //dispatch(showUpdate(resp.data))
                dispatch(initialize('userData', resp.data));
                //console.log (resp.data)
                //initialize('userData', resp.data)
            })
            .catch(e => {
                console.log (e);
                //e.response.data.errors.forEach(error => toastr.error('Erro', error))
            })
    }

}

export function showUpdate(general) {
    console.log(general);
    return [ 
        initialize('userData', general)
    ]
}


function submitUser(values, method) {
    console.log (values);
    return dispatch => {
        const id = values.id ? values.id : '';
        axios[method](`${API_URL}/club/updateuser/1`, values, {
            headers: {
              'Authorization': 'Bearer ' + window.localStorage.getItem('token')
            }
          })
            .then(resp => {
                //toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                //dispatch(showUpdate(resp.data))
                console.log(resp.data)
                dispatch(autoLogin());
                swal("Salvo com Sucesso!", {
                    icon: "success",
                    timer: 2000
                    });               

            })
            .catch(e => {
                console.log (e);
                //e.response.data.errors.errorInfo.forEach(error => toastr.error('Erro', error))
            })
    }
}



function submitAvatar(values, method) {
    values['avatar'] =
        { avatarStyle : values.avatarStyle,
            topType :  values.topType,
            accessoriesType: values.accessoriesType,
            hairColor: values.hairColor,
            facialHairType : values.facialHairType,
            clotheType : values.clotheType,
            clotheColor : values.clotheColor,
            eyeType : values.eyeType,
            eyebrowType : values.eyebrowType,
            mouthType : values.mouthType,
            skinColor : values.skinColor
        };

    console.log (values);
    return dispatch => {
        const id = values.id ? values.id : '';
        axios[method](`${API_URL}/club/updateuser/1`, values, {
            headers: {
              'Authorization': 'Bearer ' + window.localStorage.getItem('token')
            }
          })
            .then(resp => {
                //toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                //dispatch(showUpdate(resp.data))
                console.log(resp.data)
                dispatch(autoLogin());
                swal("Salvo com Sucesso!", {
                    icon: "success",
                    timer: 2000
                    });               

            })
            .catch(e => {
                console.log (e);
                //e.response.data.errors.errorInfo.forEach(error => toastr.error('Erro', error))
            })
    }
}

