import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';

export default class MymodalSite extends Component {
 

    
  render () {

    const checaextensao = () => {

      var arq = this.props.imagem;
      var ext = arq.split('.').pop();

      if (ext === 'gif' || ext === 'jpeg' || ext === 'jpg' || ext === 'png') {
        return (
          <img className="img-fluid rounded" src={process.env.PUBLIC_URL + arq} alt=""/>
        )} else if (ext === 'pdf') {
          return (
            <iframe src={process.env.PUBLIC_URL + arq} style={{ width: '100%', height: '50vh', border : 'none'}} title={ext}></iframe>
          )} else if (ext === 'mp3' || ext === 'wav' || ext === 'm4a') {
            return (
              <audio controls>
              <source src={process.env.PUBLIC_URL + arq} type="audio/mpeg"/>
              </audio>
          )} else {
            return (
              <a href={process.env.PUBLIC_URL + arq} target="_blank" rel="noreferrer">Clique para abrir</a>  
            )
          }

    }


    return (
 
      <Modal
      {...this.props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        <h2 className="portfolio-modal-title text-secondary mb-0">{this.props.titulo + ' - ' + this.props.imagem.split('.').pop()}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body2">
      <div className="row justify-content-center">
              <div className="col-lg-12 text-center">
                {checaextensao()}
              </div>
            </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer2">
      </Modal.Footer>
    </Modal>
 
      );
  }
}
