import React from 'react';
import CoursesHeaderNav from './CoursesHeaderNav';

const CoursesHeader = () => {

  return (
    <header>
      <CoursesHeaderNav />
    </header>
  );
};

export default CoursesHeader;
