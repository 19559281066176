import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadNewPhotos } from '../../store/photos';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const Notificacoes = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [area, setArea] = React.useState(searchParams.get("id"));

  React.useEffect(() => {
    console.log(area);
  }, []);

  return (
    <li className="nav-item dropdown">
    <a className="nav-link" data-toggle="dropdown" href="#">
      <i className="far fa-bell"></i>
      <span className="badge badge-warning navbar-badge">15</span>
    </a>
    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
      <span className="dropdown-item dropdown-header">15 Notificações</span>
      <div className="dropdown-divider"></div>
      <a href="#" className="dropdown-item">
        <i className="fas fa-envelope mr-2"></i> 4 novas mensagens
        <span className="float-right text-muted text-sm">3 mins</span>
      </a>
      <div className="dropdown-divider"></div>
      <a href="#" className="dropdown-item">
        <i className="fas fa-users mr-2"></i> 8 solicitações de amizade
        <span className="float-right text-muted text-sm">12 hours</span>
      </a>
      <div className="dropdown-divider"></div>
      <a href="#" className="dropdown-item">
        <i className="fas fa-file mr-2"></i> 3 novas ideias
        <span className="float-right text-muted text-sm">2 days</span>
      </a>
      <div className="dropdown-divider"></div>
      <a href="#" className="dropdown-item dropdown-footer">Ver todas as notificações</a>
    </div>
  </li>
  );

};

export default Notificacoes;
