import IO from 'socket.io-client';

export const appSocket = IO("https://mybrainchat.herokuapp.com/", {
  secure: true
});

export const BASE_URL = 'https://www.maiscomprados.com.br/'
export const API_URL = 'https://www.maiscomprados.com.br/api'
export const rootUrl = 'https://www.maiscomprados.com.br/api'
export const MP_PUBLIC_KEY = localStorage.getItem('token');
export const PS_PUBLIC_KEY_OFICIAL = '053AE8DDDC704E6FAB2C1A874AEBD593'
export const PS_PUBLIC_KEY = 'E882B2F7A5384444B0197A7BDA2FF4DE'