import React from 'react';

const Erros = ({ errors }) => {
  if (!errors) return null;
  let erros = [];
  
  Object.entries(errors).forEach(entry => {
    //const [key, value] = entry;
    const [value] = entry;
    erros.push (value[0]);
  });
  
  return erros.map((er,index) => <p key={index} style={{ color: '#f31', margin: '1rem 0' }}>{er}</p>);

};

export default Erros;