import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadNewPhotos } from '../../store/photos';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { appSocket } from '../../config/App';

const Mensagens = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [area, setArea] = React.useState(searchParams.get("id"));
  const [msg, setMensagens] = React.useState([]);

  React.useEffect(() => {
    //console.log(area);
  }, []);

  React.useEffect(() => {

    //setMensagens(msg.slice(1));

    appSocket.on("atualizar mensagens", (mensagens: MensagemPayload[]) => {
      //console.log(mensagens)
      setMensagens(msg => [...msg, mensagens]);
      apagar();
      //this.setState({ cards: this.state.cards.slice(1) })
    });

  }, []);

  React.useEffect(() => {

    if (msg.length > 5) setMensagens(msg.slice(1));

  }, [msg]);

  function apagar() {
  }


  return (
    
    <li className="nav-item dropdown">
    <a className="nav-link" data-toggle="dropdown" href="#">
      <i className="far fa-comments"></i>
      <span className="badge badge-danger navbar-badge">{msg?.length}</span>
    </a>
    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
    {msg?.map((item,i) => (
      <div key={i}>
      <a key={i} href="#" onClick={() => apagar()} className="dropdown-item">
        <div className="media">
          <div className="media-body">
            <h3 className="dropdown-item-title">
            {item.apelido}
              <span className="float-right text-sm text-warning"><i className="fas fa-star"></i></span>
            </h3>
            <p className="text-sm">{item.msg}</p>
            <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i>...</p>
          </div>
        </div>
      </a>
      <div className="dropdown-divider"></div>
      </div>
      ))}

<a className="dropdown-item dropdown-footer" data-widget="control-sidebar" data-slide="true" href="#" role="button">
Ver todas as mensagens
        </a>

    </div>
  </li>
  );

};

interface MensagemPayload {
  nome: string;
  mensagem: string;
}

export default Mensagens;
