import React from 'react';
import { UserContext } from '../../UserContext';
import styles from './PhotoComments.module.css';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios';
import FeedModal from '../Feed/FeedModal';
import { API_URL } from '../../Api';

const PhotoIdeas = (props) => {
  var [ideas, setIdeas] = React.useState(() => props.ideas);
  const [modalPhoto, setModalPhoto] = React.useState(null);
  const { login } = React.useContext(UserContext);
  var items = [];
  var send = [];

  React.useEffect(() => {
  }, []);

  function handleOnDragEnd  (result) {
    if (!result.destination) return;

    items = Array.from(ideas);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    
    setIdeas(items);

    let ideias = {};
    items.forEach(function(item) {
      send.push({'id' : item['id']});
    });
   

    setOrder(props.id,send);
  }

  function setModalPhotoLocal(photo) {
    console.log(photo);
    setModalPhoto(photo);
  }

  function setOrder (id,ideas) {

    axios ({
        method: 'POST',
        url: `${API_URL}/club/site/order/${id}`,
        data: {'id':id,'ideas':ideas}
    }).then(function (response) {
        console.log(response);
    }).catch(function (error) {
        console.log('Error: ', error);
    });
  }

  return (
    
    <div className="container">
    <div className="row">
    {modalPhoto && (
        <FeedModal photo={modalPhoto} setModalPhoto={setModalPhoto} />
      )}
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="characters">
                    {(provided) => (
                      <ul className="characters" style={{ padding: 0, margin: 0, height: 'auto', overflowY: 'unset'}} {...provided.droppableProps} ref={provided.innerRef}>

                        {ideas && ideas?.map((photo, index) => {
                          return (
                            <Draggable key={photo.id} draggableId={`C${photo.id}`} index={index}>
                              {(provided) => (
                                <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} key={index} color="#424242" style={{justifyContent: 'space-between'}}>
                                {photo.description}
                                <button onClick={() => setModalPhotoLocal(photo)} className='btn btn-primary'>Editar</button>
                                </li>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>

    </div>
   </div>    
  );
};

export default PhotoIdeas;
