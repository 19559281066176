import React from 'react';
import Input from '../Forms/Input';
import Button from '../Forms/Button';
import Error from '../Helper/Error';
import useForm from '../../Hooks/useForm';
import { USER_POST } from '../../Api';
import { UserContext } from '../../UserContext';
import useFetch from '../../Hooks/useFetch';
import Head from '../Helper/Head';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userLogin } from '../../store/user';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const LoginCreate = () => {
  const getUser = useSelector((state) => state.user);
  const name = useForm();
  const email = useForm('email');
  const password = useForm();
  const navigate = useNavigate();

  //const { userLogin } = React.useContext(UserContext);
  //const { loading, error, request } = useFetch();

  const dispatch = useDispatch();
  const { loading, error, request } = useFetch();

  React.useEffect(() => {
    //if (getUser.data) navigate('/conta');
  }, []);

/*
  async function handleSubmit(event) {
    event.preventDefault();
    const { url, options } = USER_POST({
      name: username.value,
      username: username.value,
      email: email.value,
      password: password.value,
    });
    const { response } = await request(url, options);
    //console.log(response);
    //console.log('aquiii');
    if (response.ok) userLogin(username.value, email.value, password.value);
  }
*/

async function handleSubmit(event) {
  event.preventDefault();
  const { url, options } = USER_POST({
    name: name.value,
    username: name.value,
    email: email.value,
    password: password.value,
  });
  const { response } = await request(url, options);
  if (response.ok)
    dispatch(
      userLogin({ username: name.value, email: email.value, password: password.value }),
    );

}

 
  return (
    <section className="animeLeft">
      <Head title="Crie sua conta" />
      <h1 className="title">Cadastre-se</h1>
      <form onSubmit={handleSubmit}>
        <Input label="Nome" type="text" name="nome" {...name} />
        <Input label="Email" type="email" name="email" {...email} />
        <Input label="Senha" type="password" name="password" {...password} />
        {loading ? (
          <Button disabled>Cadastrando...</Button>
        ) : (
          <Button>Cadastrar</Button>
        )}
        <Error error={error} />
      </form>
    </section>
  );
};

export default LoginCreate;
