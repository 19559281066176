import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadNewPhotos } from '../../store/photos';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useFetch from '../../Hooks/useFetch';
import { GRUPOS_GET, SUBGRUPOS_GET } from '../../Api';
import Modulos from './Modulos';
import axios from 'axios';
import { API_URL } from '../../config/App';

const Etapas = (props) => {
  const {request, data } = useFetch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [area, setArea] = React.useState(searchParams.get("id"));
  const [progress, setProgress] = React.useState(0);
  const [areadesc, setAreadesc] = React.useState(searchParams.get("desc"));
  const [modulo, setModulo] = React.useState(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    async function getData() {

      let id = searchParams.get("id");
      let desc = searchParams.get("desc");

      const { url, options } = GRUPOS_GET(id);
      const { json } = await request(url, options);
      setModulo(null);
      setArea(id);
      
      if (desc != null) {
        dispatch({ type: 'SETAREA', payload: desc });
        dispatch({ type: 'SETPLAYLIST', payload: id });
      }

      getProgress (props.user.id,id);

    }
    getData();
  }, [request,searchParams]);

  function abrirModulo(item) {
    setModulo(item);
  }

  function getProgress (user_id,area_id) {
    console.log(user_id,area_id)
    var _self = this;
    axios ({
        method: 'GET',
        url: `${API_URL}/club/getmyprogress/${user_id}/${area_id}`
    }).then(function (response) {

        if (response.data[0]) {
          setProgress(response.data[0].progress);
        } else {
          setProgress(0);
        }

    }).catch(function (error) {
        console.log('Error: ', error);
    });
}

  return (

  <div style={{padding: '1rem 1rem'}}>
    <div style={{ backgroundColor: '#f8f9fc',padding: '5px', borderRadius: '5px',
    margin: '5px 0 15px 0',  border: '#eaecf4 1px solid'}}>
        <h6>Progresso Geral</h6>
        <div className="progress">
            <div className="progress-bar" role="progressbar" style={{width: progress +'%'}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
          </div>
    </div>

  <div className="formatoi">
  <ul className="nav nav-treeview formatoitemi" style={{ marginBottom: '10px' }}>
      {data?.map((item,i) => (
              <li key={i} className="nav-item">
              <button className="btn btn-primary m-2" onClick={() => abrirModulo(item.id)}>{item.title}</button>
            </li>
    ))}
    </ul>

    {modulo && <Modulos id={modulo} area={area}/>}
    </div>
    </div>   
);

};

export default Etapas;

