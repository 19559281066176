import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { updateAvatar } from '../../actions/generalActions'
import Avatar from "avataaars"

class AvatarForm extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            times: 0,
            avatarStyle: 'Circle',
            topType: 'LongHairBob',
            accessoriesType: 'Blank',
            hairColor: 'Brown',
            facialHairType: 'Blank',
            clotheType: 'ShirtCrewNeck',
            clotheColor: 'Heather',
            eyeType: 'Side',
            eyebrowType: 'Default',
            mouthType: 'Twinkle',
            skinColor: 'Light'             
        };

        this.onAvatarStyleChanged = this.onAvatarStyleChanged.bind(this);
        this.onAvatartopTypeChanged = this.onAvatartopTypeChanged.bind(this);
        this.onAvataraccessoriesTypeChanged = this.onAvataraccessoriesTypeChanged.bind(this);
        this.onAvatarhairColorChanged = this.onAvatarhairColorChanged.bind(this);
        this.onAvatarfacialHairTypeChanged = this.onAvatarfacialHairTypeChanged.bind(this);
        this.onAvatarclotheTypeChanged = this.onAvatarclotheTypeChanged.bind(this);
        this.onAvatarclotheColorChanged = this.onAvatarclotheColorChanged.bind(this);
        this.onAvatareyeTypeChanged = this.onAvatareyeTypeChanged.bind(this);
        this.onAvatareyebrowTypeChanged = this.onAvatareyebrowTypeChanged.bind(this);
        this.onAvatarmouthTypeChanged = this.onAvatarmouthTypeChanged.bind(this);
        this.onAvatarskinColorChanged = this.onAvatarskinColorChanged.bind(this);         
        this.handleSubmitlocal = this.handleSubmitlocal.bind(this);    
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.times >= this.state.times) {
            this.setState({
                times: this.state.times + 1
            });
        }

    }

    handleSubmitlocal(event) {
        event.preventDefault();
        const dados = this.state;
        console.log('verrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr');
        console.log(dados);
        this.props.updateAvatar(dados);
      }


    onAvatarStyleChanged (e) {
        this.setState({
            avatarStyle: e.currentTarget.value
            });
    }

    onAvatartopTypeChanged (e) {
        this.setState({
            topType: e.currentTarget.value
            });
    }

    onAvataraccessoriesTypeChanged (e) {
        this.setState({
            accessoriesType: e.currentTarget.value
            });
    }

    onAvatarhairColorChanged (e) {
        this.setState({
            hairColor: e.currentTarget.value
            });
    }

    onAvatarfacialHairTypeChanged (e) {
        this.setState({
            facialHairType: e.currentTarget.value
            });
    }

    onAvatarclotheTypeChanged (e) {
        this.setState({
            clotheType: e.currentTarget.value
            });
    }

    onAvatarclotheColorChanged (e) {
        this.setState({
            clotheColor: e.currentTarget.value
            });
    }

    onAvatareyeTypeChanged (e) {
        this.setState({
            eyeType: e.currentTarget.value
            });
    }

    onAvatareyebrowTypeChanged (e) {
        this.setState({
            eyebrowType: e.currentTarget.value
            });
    }

    onAvatarmouthTypeChanged (e) {
        this.setState({
            mouthType: e.currentTarget.value
            });
    }

    onAvatarskinColorChanged (e) {
        this.setState({
            skinColor: e.currentTarget.value
            });
    }

    componentWillMount() {
        //initialize('avatarForm', this.props.getDataAvatar())
        //this.props.getDataAvatar();

        var self = this;
        //setTimeout(function() {
            var todos = self.props.avatarx.data.avatar;
            self.setState (JSON.parse(todos));
        //}, 1000);

    }

    render() {

        const { handleSubmit, readOnly, avatarx } = this.props

        return (
            <form onSubmit={this.handleSubmitlocal} role='form' >
            <div className='container'>
            <div className='row'>

                <div className='col-md-6'>
                <div className='d-flex justify-content-center'>            

                    <div className='col-md-12'>
                        <div className='form-group' style={{ textAlign: 'center' }}>
                            <Avatar
                               {...this.state}
                            />
                        </div>
                    </div>

                </div>
                </div>

                <div className='col-md-6'>
                <div> 


                <div className='col-md-12'>
                    <div className="row form-group my-2">
                        <label className="col-md-6 form-label" htmlFor="avatar-style">Estilo</label>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-around">
                            <label>
                                <input type="radio" name="avatarStyle" 
                                checked={this.state.avatarStyle === 'Circle'} 
                                onChange={(e) => this.onAvatarStyleChanged(e)}
                                name="avatar-style" value="Circle" /> Circulo</label>
                            <label>
                                <input type="radio" name="avatarStyle" 
                                checked={this.state.avatarStyle === 'Transparent'} 
                                onChange={(e) => this.onAvatarStyleChanged(e)}
                                name="avatar-style" value="Transparent" /> Transparente</label>
                            </div>    
                        </div>
                    </div>
                    </div>

                    <div className='col-md-12'>
                    <div className="row form-group my-2">
                        <label className="col-md-6 form-label" htmlFor="topType">Cabeça</label>
                        <div className="col-md-6">
                            <select id="topType" name="topType" className="form-control" onChange={this.onAvatartopTypeChanged} value={this.state.topType}>
                                <option value="NoHair">NoHair</option>
                                <option value="Eyepatch">Eyepatch</option>
                                <option value="Hat">Hat</option>
                                <option value="Hijab">Hijab</option>
                                <option value="Turban">Turban</option>
                                <option value="WinterHat1">WinterHat1</option>
                                <option value="WinterHat2">WinterHat2</option>
                                <option value="WinterHat3">WinterHat3</option>
                                <option value="WinterHat4">WinterHat4</option>
                                <option value="LongHairBigHair">LongHairBigHair</option>
                                <option value="LongHairBob">LongHairBob</option>
                                <option value="LongHairBun">LongHairBun</option>
                                <option value="LongHairCurly">LongHairCurly</option>
                                <option value="LongHairCurvy">LongHairCurvy</option>
                                <option value="LongHairDreads">LongHairDreads</option>
                                <option value="LongHairFrida">LongHairFrida</option>
                                <option value="LongHairFro">LongHairFro</option>
                                <option value="LongHairFroBand">LongHairFroBand</option>
                                <option value="LongHairNotTooLong">LongHairNotTooLong</option>
                                <option value="LongHairShavedSides">LongHairShavedSides</option>
                                <option value="LongHairMiaWallace">LongHairMiaWallace</option>
                                <option value="LongHairStraight">LongHairStraight</option>
                                <option value="LongHairStraight2">LongHairStraight2</option>
                                <option value="LongHairStraightStrand">LongHairStraightStrand</option>
                                <option value="ShortHairDreads01">ShortHairDreads01</option>
                                <option value="ShortHairDreads02">ShortHairDreads02</option>
                                <option value="ShortHairFrizzle">ShortHairFrizzle</option>
                                <option value="ShortHairShaggyMullet">ShortHairShaggyMullet</option>
                                <option value="ShortHairShortCurly">ShortHairShortCurly</option>
                                <option value="ShortHairShortFlat">ShortHairShortFlat</option>
                                <option value="ShortHairShortRound">ShortHairShortRound</option>
                                <option value="ShortHairShortWaved">ShortHairShortWaved</option>
                                <option value="ShortHairSides">ShortHairSides</option>
                                <option value="ShortHairTheCaesar">ShortHairTheCaesar</option>
                                <option value="ShortHairTheCaesarSidePart">ShortHairTheCaesarSidePart</option>
                            </select>
                        </div>
                    </div>
                    </div>


                    <div className='col-md-12'>
                    <div className="row form-group my-2">
                        <label className="col-md-6 form-label" htmlFor="accessoriesType">↳ 👓 Acessorios</label>
                        <div className="col-md-6">
                            <select id="accessoriesType" name="accessoriesType" onChange={this.onAvataraccessoriesTypeChanged} value={this.state.accessoriesType} className="form-control">
                                <option value="Blank">Blank</option>
                                <option value="Kurt">Kurt</option>
                                <option value="Prescription01">Prescription01</option>
                                <option value="Prescription02">Prescription02</option>
                                <option value="Round">Round</option>
                                <option value="Sunglasses">Sunglasses</option>
                                <option value="Wayfarers">Wayfarers</option>
                            </select>
                        </div>
                    </div>
                    </div>


                    <div className='col-md-12'>
                    <div className="row form-group my-2">
                        <label className="col-md-6 form-label" htmlFor="facialHairType">Pêlos da face</label>
                        <div className="col-md-6">
                            <select id="facialHairType" name="facialHairType" onChange={this.onAvatarfacialHairTypeChanged} value={this.state.facialHairType} className="form-control">
                                <option value="Blank">Blank</option>
                                <option value="BeardMedium">BeardMedium</option>
                                <option value="BeardLight">BeardLight</option>
                                <option value="BeardMajestic">BeardMajestic</option>
                                <option value="MoustacheFancy">MoustacheFancy</option>
                                <option value="MoustacheMagnum">MoustacheMagnum</option>
                            </select>
                        </div>
                    </div>
                    </div>


                    <div className='col-md-12'>
                    <div className="row form-group my-2">
                        <label className="col-md-6 form-label" htmlFor="facialHairColor">↳ ✂️ Cor dos pêlos</label>
                        <div className="col-md-6">
                            <select id="facialHairColor" name="facialHairColor" onChange={this.onAvatarhairColorChanged} value={this.state.hairColor} className="form-control">
                                <option value="Auburn">Auburn</option>
                                <option value="Black">Black</option>
                                <option value="Blonde">Blonde</option>
                                <option value="BlondeGolden">BlondeGolden</option>
                                <option value="Brown">Brown</option>
                                <option value="BrownDark">BrownDark</option>
                                <option value="Platinum">Platinum</option>
                                <option value="Red">Red</option>
                            </select>
                        </div>
                    </div>
                    </div>


                    <div className='col-md-12'>
                    <div className="row form-group my-2">
                        <label className="col-md-6 form-label" htmlFor="clotheType">👔 Roupas</label>
                        <div className="col-md-6">
                            <select id="clotheType" name="clotheType" onChange={this.onAvatarclotheTypeChanged} value={this.state.clotheType} className="form-control">
                                <option value="BlazerShirt">BlazerShirt</option>
                                <option value="BlazerSweater">BlazerSweater</option>
                                <option value="CollarSweater">CollarSweater</option>
                                <option value="GraphicShirt">GraphicShirt</option>
                                <option value="Hoodie">Hoodie</option>
                                <option value="Overall">Overall</option>
                                <option value="ShirtCrewNeck">ShirtCrewNeck</option>
                                <option value="ShirtScoopNeck">ShirtScoopNeck</option>
                                <option value="ShirtVNeck">ShirtVNeck</option>
                            </select>
                        </div>
                    </div>
                    </div>



                    <div className='col-md-12'>
                    <div className="row form-group my-2">
                        <label className="col-md-6 form-label" htmlFor="clotheColor">↳ Cor da roupa</label>
                        <div className="col-md-6">
                            <select id="clotheColor" name="clotheColor" onChange={this.onAvatarclotheColorChanged} value={this.state.clotheColor} className="form-control">
                                <option value="Black">Black</option>
                                <option value="Blue01">Blue01</option>
                                <option value="Blue02">Blue02</option>
                                <option value="Blue03">Blue03</option>
                                <option value="Gray01">Gray01</option>
                                <option value="Gray02">Gray02</option>
                                <option value="Heather">Heather</option>
                                <option value="PastelBlue">PastelBlue</option>
                                <option value="PastelGreen">PastelGreen</option>
                                <option value="PastelOrange">PastelOrange</option>
                                <option value="PastelRed">PastelRed</option>
                                <option value="PastelYellow">PastelYellow</option>
                                <option value="Pink">Pink</option>
                                <option value="Red">Red</option>
                                <option value="White">White</option>
                            </select>
                        </div>
                    </div>
                    </div>


                    <div className='col-md-12'>
                    <div className="row form-group my-2">
                        <label className="col-md-6 form-label" htmlFor="eyeType">👁 Olhos</label>
                        <div className="col-md-6">
                            <select id="eyeType" name="eyeType" onChange={this.onAvatareyeTypeChanged} value={this.state.eyeType} className="form-control">
                                <option value="Close">Close</option>
                                <option value="Cry">Cry</option>
                                <option value="Default">Default</option>
                                <option value="Dizzy">Dizzy</option>
                                <option value="EyeRoll">EyeRoll</option>
                                <option value="Happy">Happy</option>
                                <option value="Hearts">Hearts</option>
                                <option value="Side">Side</option>
                                <option value="Squint">Squint</option>
                                <option value="Surprised">Surprised</option>
                                <option value="Wink">Wink</option>
                                <option value="WinkWacky">WinkWacky</option>
                            </select>
                        </div>
                    </div>
                    </div>


                    <div className='col-md-12'>
                    <div className="row form-group my-2">
                        <label className="col-md-6 form-label" htmlFor="eyebrowType">✏️ Sobrancelha</label>
                        <div className="col-md-6">
                            <select id="eyebrowType" name="eyebrowType" onChange={this.onAvatareyebrowTypeChanged} value={this.state.eyebrowType} className="form-control">
                                <option value="Angry">Angry</option>
                                <option value="AngryNatural">AngryNatural</option>
                                <option value="Default">Default</option>
                                <option value="DefaultNatural">DefaultNatural</option>
                                <option value="FlatNatural">FlatNatural</option>
                                <option value="RaisedExcited">RaisedExcited</option>
                                <option value="RaisedExcitedNatural">RaisedExcitedNatural</option>
                                <option value="SadConcerned">SadConcerned</option>
                                <option value="SadConcernedNatural">SadConcernedNatural</option>
                                <option value="UnibrowNatural">UnibrowNatural</option>
                                <option value="UpDown">UpDown</option>
                                <option value="UpDownNatural">UpDownNatural</option>
                            </select>
                        </div>
                    </div>
                    </div>



                    <div className='col-md-12'>
                    <div className="row form-group my-2">
                        <label className="col-md-6 form-label" htmlFor="mouthType">👄 Boca</label>
                        <div className="col-md-6">
                            <select id="mouthType" name="mouthType" onChange={this.onAvatarmouthTypeChanged} value={this.state.mouthType} className="form-control">
                                <option value="Concerned">Concerned</option>
                                <option value="Default">Default</option>
                                <option value="Disbelief">Disbelief</option>
                                <option value="Eating">Eating</option>
                                <option value="Grimace">Grimace</option>
                                <option value="Sad">Sad</option>
                                <option value="ScreamOpen">ScreamOpen</option>
                                <option value="Serious">Serious</option>
                                <option value="Smile">Smile</option>
                                <option value="Tongue">Tongue</option>
                                <option value="Twinkle">Twinkle</option>
                                <option value="Vomit">Vomit</option>
                            </select>
                        </div>
                    </div>
                    </div>


                    <div className='col-md-12'>
                    <div className="row form-group my-2">
                        <label className="col-md-6 form-label" htmlFor="skinColor">🎨 Cor da pele</label>
                        <div className="col-md-6">
                            <select id="skinColor" name="skinColor" onChange={this.onAvatarskinColorChanged} value={this.state.skinColor} className="form-control">
                                <option value="Tanned">Tanned</option>
                                <option value="Yellow">Yellow</option>
                                <option value="Pale">Pale</option>
                                <option value="Light">Light</option>
                                <option value="Brown">Brown</option>
                                <option value="DarkBrown">DarkBrown</option>
                                <option value="Black">Black</option>
                            </select>
                        </div>
                    </div>
                    </div>

                    <div className='col-md-12'>
                    <button className="btn btn-primary float-end my-2" type="submit">Salvar</button>
                    </div>

                </div>

                </div>
                </div>
                </div>
            </form>
        )
    }
}

AvatarForm = reduxForm({ form: 'avatarForm', destroyOnUnmount: false })(AvatarForm)
const mapStateToProps = state => ({avatarx: state.user})
const mapDispatchToProps = dispatch => bindActionCreators({ updateAvatar }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(AvatarForm)