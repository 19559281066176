import React from 'react';
import FeedPhotosItemBig from './FeedPhotosItemBig';
import useFetch from '../../Hooks/useFetch';
import { PHOTOS_GET } from '../../Api';
import Error from '../Helper/Error';
import Loading from '../Helper/Loading';
import styles from './FeedPhotos.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { loadNewPhotos } from '../../store/photos';

const FeedPhotosBig = ({ page, user, setModalPhoto, setInfinite }) => {
  //const { data, loading, error, request } = useFetch();
  const { data, pages, infinite, loading } = useSelector((state) => state.photos);
  const dispatch = useDispatch();

  React.useEffect(() => {
    async function fetchPhotos() {
      const total = 5;
      console.log ('page:');
      console.log (page);
      console.log ('pages:');
      console.log (pages);
      dispatch(loadNewPhotos(page));
      //const { url, options } = PHOTOS_GET({ page, total, user });
      //const { response, json } = await request(url, options);
      //console.log(response);
      //console.log(json);
      //console.log(json.meta.total);
      //console.log(total);
      //if (response && response.ok && json.meta.current_page === json.meta.last_page) setInfinite(false);
      setInfinite(infinite);
    }
    fetchPhotos();
  }, []);

  function tipo() {
    let valor = Math.floor(Math.random() * 4);
    let resultado = '';
    switch(parseInt(valor)) {
      case 0 : resultado = 'photo';
        break;
      case 1 : resultado = 'blog';
        break;
      case 2 : resultado = 'project';
        break;
      default: resultado = 'photo';      
    }
    return resultado;
  }

  //if (error) return <Error error={error} />;
  if (loading) return <Loading />;
  if (data)
    return (
      <>
        {data.data.map((photo) => (
          <FeedPhotosItemBig
            key={photo.id}
            photo={photo}
            tipo={tipo()}
            setModalPhoto={setModalPhoto}
          />
        ))}
      </>
    );
  else return null;
};

export default FeedPhotosBig;
