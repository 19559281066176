import React from 'react';
import styles from './search.module.css';

const Search = ({ label, type, name, value, onChange, error, onBlur, invisible, Procurar }) => {
  if (!invisible) {
  return (
    <div className={styles.wrapper} >
      <input
        id={name}
        name={name}
        className={styles.input}
        type={type}
        value={value}
        onChange={onChange}
        placeholder="Pesquisar..."
        onBlur={onBlur}
      />
    <button onClick={(e) => Procurar(e)}><i className="fa fa-search"></i></button>
    </div>
  );
} else return null
};

export default Search;
