import React from 'react';

const AreasContentItem = ({ e, tipo, photo, setModalPhoto, Change }) => {
  var item, grid, rowHeight, rowGap, rowSpan, allItems, item  = '';
  
  function handleClick() {
    setModalPhoto(photo);
  }

  function resizeGridItemX(e){

    try {
      setTimeout(() => {
        item = document.getElementsByClassName("item")[e];
      grid = document.getElementsByClassName("grid")[0];
      rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
      rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
      rowSpan = Math.ceil((item.querySelector('.content').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
        item.style.gridRowEnd = "span "+rowSpan;
  
      }, 1000)
  }
   catch (exception) {
      
   }

  }

  return (
    <div className={`item ${tipo}`}  onClick={handleClick}>
    <div className="content" style={{position: 'relative'}}>
      <div className="title">
        <h3>{photo.title}</h3>
      </div>
      <img loading="lazy" style={{ display : 'none'}} className="photothumb scale-up-center" src={photo.src} alt={photo.title}/>
    </div>
  </div>
  );
};

export default AreasContentItem;
