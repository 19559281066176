import React from 'react';
import styles from './search.module.css';

const InputChat = ({ label, type, name, value, onChange, error, onBlur, invisible, Procurar, Clique, Focus }) => {
  if (!invisible) {
  return (
    <div className={styles.wrapper} >
      <input
        id={name}
        name={name}
        className={styles.input}
        type={type}
        value={value}
        onChange={onChange}
        onClick={(e) => Clique(e)}
        placeholder="Sua mensagem..."
        onBlur={(e) => Focus(e)}
      />
    <button type="button" onClick={(e) => Procurar(e)}><i className="fa fa-smile-o"></i></button>
    </div>
  );
} else return null
};

export default InputChat;
