import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import thunk from 'redux-thunk'
import photo from './photo';
import site from './site';
import photoPost from './photoPost';
import token from './token';
import user from './user';
import feed from './feed';
import feedsites from './feedsites';
import feed2 from './feed2';
import ui from './ui';
import date from './date';
import inc2 from './incrementar';
import logger from './middleware/logger';
import localStorage from './middleware/localStorage';
import modal from './modal';
import login from './login';
import photos from './photos';
import sites from './sites';
import { reducer as formReducer } from 'redux-form'

function inc(state = 0, action) {
  switch (action.type) {
    case 'INCREMENTAR':
      return state + 1;
    default:
      return state;
  }
}

function area (state = '', action) {
 // console.group(action);
  switch (action.type) {
    case 'SETAREA':
      return state = action.payload;
    default:
      return state;
  }
}

function playlistdata (state = '', action) {
  //console.group(action);
   switch (action.type) {
     case 'SETPLAYLIST':
       return state = action.payload;
     default:
       return state;
   }
 }
 
const middleware = [...getDefaultMiddleware(), thunk, logger, localStorage];

const reducer = combineReducers({ photo, site, photoPost, token, user, feed, feedsites, feed2, ui, date, modal, inc, inc2, login, sites, photos, playlistdata, area, form: formReducer });
const store = configureStore({ reducer, middleware });

export default store;
