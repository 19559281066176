import React from 'react';
import styles from './Feed.module.css';
import FeedModal from './FeedModal2';
import FeedPhotos from './FeedPhotos2';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { loadNewPhotos, loadAll, resetFeedState, setCategory } from '../../store/feed2';
import Loading from '../Helper/Loading';
import Error from '../Helper/Error';
import Avatar from "avataaars";
import axios from 'axios';
import Playlist from '../Courses/Playlistout';
import { Link, useNavigate } from 'react-router-dom';
import { API_URL } from '../../Api';

const Feed2 = ({ user, links, videos, title }) => {

  const [categories, setCategories] = React.useState([]);
  const [playmusic, setPlaymusic] = React.useState(false);
  //const [category, setCategory] = React.useState(0);

const [modalPhoto, setModalPhoto] = React.useState(null);
const { infinite, loading, list, avatar, userfeed, data, status, error } = useSelector((state) => state.feed2);
const dispatch = useDispatch();

React.useEffect(() => {
  getCategories();
}, []);


React.useEffect(() => {
  console.log('mudou');
  dispatch(resetFeedState());
  if (links || videos) {
    dispatch(loadAll({ user, total: 1000, links, videos }));
  } else {
    dispatch(loadNewPhotos({ user, total: 8, links, videos }));
  }
  
}, [dispatch, user, title]);

React.useEffect(() => {
  let wait = false;
  function infiniteScroll() {
    if (infinite) {
      const scroll = window.scrollY;
      const height = document.body.offsetHeight - window.innerHeight;
      if (scroll > height * 0.75 && !wait) {
        dispatch(loadNewPhotos({ user, total: 8, links, videos}));
        wait = true;
        setTimeout(() => {
          wait = false;
        }, 500);
      }
    }
  }

  window.addEventListener('wheel', infiniteScroll);
  window.addEventListener('scroll', infiniteScroll);
  return () => {
    window.removeEventListener('wheel', infiniteScroll);
    window.removeEventListener('scroll', infiniteScroll);
  };
}, [infinite, dispatch, user]);


function getCategories () {
  axios.get(`${API_URL}/getcategories`)
  .then(res => {
      setCategories(res.data);
  })
}

function sendCategory(item) {
  if (item == 1) item = 0; 
  //setCategory(item);
  dispatch(setCategory(item));
  dispatch(resetFeedState());
  dispatch(loadNewPhotos({ user, total: 8, links, videos }));
  setPlaymusic(false);
}

const renderTodos = categories?.map((item, i) => {
  return  (<div key={i}>
    <button onClick={(e) => sendCategory(item.id)} className="btn btn-primary m-1 p-1" style={{margin: '0 auto', width: '90%', padding: '10%'}}>{ item.name }</button>
  </div>)
});

return (
  <div className={styles.outside} >
        <div className={styles.perfil}>
        {avatar.length > 0 && <Avatar {...avatar[0]} />}
        {userfeed.length > 0 && <p className='p-3'>{userfeed.username}</p>}
        {renderTodos}
        <button onClick={(e) => setPlaymusic(!playmusic)} className="btn btn-primary m-1 p-1" style={{margin: '0 auto', width: '90%', padding: '10%'}}>Playlist</button>
        </div>

        <FeedModal photo={modalPhoto} setModalPhoto={setModalPhoto}/>
    {playmusic && <Playlist user={data.user}/>}
    {list.length > 0 && !playmusic && <FeedPhotos setModalPhoto={setModalPhoto}/>}
    {loading && <Loading />}
    {error && <Error error={error} />}

    {!infinite && !user && (
      <p
        style={{
          textAlign: 'center',
          padding: '2rem 0 4rem 0',
          color: '#888',
        }}
      >
        Não existem mais postagens.
      </p>
    )}
  </div>
);
};

Feed2.defaultProps = {
user: 0,
};

Feed2.propTypes = {
user: PropTypes.oneOfType([
  PropTypes.string.isRequired,
  PropTypes.number.isRequired,
]),
};

export default Feed2;