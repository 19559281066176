import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadPhotos} from '../../store/feed';
import styles from './PhotosContent.module.css';
import PhotosContentItem from './PhotosContentItem';
import { getOverFiveKg, getbyTitle } from '../../store/photos';
import FeedModal from '../Feed/FeedModal';
import { toBeDisabled } from '@testing-library/jest-dom';

const PhotosContent = (props) => {
  const { list, links, pages } = useSelector((state) => state.feed);
  let lista = getOverFiveKg(list);
  const [modalPhoto, setModalPhoto] = React.useState(null);
  const dispatch = useDispatch();
  
  React.useEffect(() => {
    //setListax(lista);
    //lista = getOverFiveKg(list);
    //setListax(getOverFiveKg(list));
    //console.log('lista');
    //console.log(listax);
    
  }, []);

  function setModalPhotoLocal(photo) {
    console.log(photo);
    setModalPhoto(photo);
  }

  const formatItem = (item,index) => {
    if (index==0) return 'Anterior';
    if (index==links.length - 1) return 'Próximo';
    return item.label;
    //{index==0 ? 'Anterior' : item.label}
    //{index==links.length - 1 ? 'Próximo' : item.label}
  }

  return (
    <div>
    {modalPhoto && (
        <FeedModal photo={modalPhoto} setModalPhoto={setModalPhoto} />
      )}
    <ul className={styles.list}>
      {list?.map((photo,index) => (
        <li key={index} className={`${styles.item} anime`}>
          <img src={photo.src} alt={photo.title} onClick={() => setModalPhotoLocal(photo)} className={styles.img} />
          <PhotosContentItem id={photo.id}  src={photo.src} title={photo.title}/>
        </li>
      ))}
    </ul>
    <div style={{ textAlign : 'center'}}>
    {links?.map((item,index) => (
        <button key={index} onClick={() => props.setPage(item.url)} disabled={item.active} className='btn btn-primary m-1'>
          {formatItem (item,index)}
        </button>
      ))}
    </div>
    </div>
  );
};

export default PhotosContent;
