import React from 'react';
import styles from './PhotoContentMax.module.css';
import { Link } from 'react-router-dom';
import PhotoComments from './SiteComments';
import PhotoFiles from './PhotoFiles';
import PhotoIdeas from './PhotoIdeas';
import PhotoDelete from './PhotoDelete';
import PhotoUpdate from './PhotoUpdate';
import Image from '../Helper/Image';
import swal from 'sweetalert';

import { LIKES_GET_SITE } from '../../Api';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSite } from '../../store/site';
import moment from 'moment';

import Magnifier from "react-magnifier";
import { GlassMagnifier } from "../../magnifier";

const PhotoContentSiteMax = ({ onClose, data, single }) => {
  //const user = React.useContext(UserContext);
  const user = useSelector((state) => state.user);
  const [iframe, setIframe] = React.useState(false);
  const [iframelink, setIframeLink] = React.useState('');
  const [layout, setLayout] = React.useState(false);
  const [html, setHtml] = React.useState('');
  const [header, setHeader] = React.useState('');
  const [footer, setFooter] = React.useState('');
  const [css, setCss] = React.useState('');
  const [js, setJs] = React.useState('');
  const { photo, comments, files, ideas } = data;
  const dispatch = useDispatch();
  const nodeRef = React.useRef();

  const ver = useSelector((state) => state.site);

  React.useEffect(() => {
    if (photo.page) abrirIframe(photo.page);
  }, []); 

  function abrirIframe(link) {
    setIframe(true);
    setIframeLink(link);
  }

  function updatePage() {
    setIframe(true);
    setIframeLink(photo.page);
    updatePageG();
  }

  function ChangeLayout() {
    setLayout(!layout);
    console.log(layout);
  }

  function updatePageG() {
    var iframe = document.getElementById('page');
    if (iframe) iframe.src = iframe.src;
  }

  async function setLike(e,id) {
    try {
      const { url, options } = LIKES_GET_SITE(id);
      const likes = await fetch(url, options);
      const result = await likes.json();
      console.log(result.likes);
      console.log(e.persist());
      dispatch(fetchSite(id));
    } catch (err) {
      console.log(err);
    } finally {
    }
  }

  function setCopiar (e,id) {
    e.preventDefault()

    var copyText = document.getElementById(id);

    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy");

    swal("Copiado com sucesso!", {
        icon: "success",
        timer: 1000
        });

  }

  function copyText(e, item) {
    e.preventDefault();

    let element = nodeRef.current;
    element.innerHTML = item;

    console.log(element);
    
    var range, selection, worked;

    if (document.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(element);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();        
      range = document.createRange();
      range.selectNodeContents(element);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    
    try {
      document.execCommand('copy');
      swal("Copiado com sucesso!", {
        icon: "success",
        timer: 1000
        });

    }
    catch (err) {
      swal("Não deu!", {
        icon: "error",
        timer: 1000
        });
    }
    
  }

  function setLike2(e,id) {
    try {
      const { url, options } = LIKES_GET_SITE(id);
      const likes = fetch(url, options);
      e.currentTarget.innerHTML = 'Curtido';
      e.currentTarget.disabled = true; 
    } catch (err) {
      console.log(err);
    } finally {
    }
  }

  const styleiframe = {
    width: '100%', 
    height: '100%',
    minHeight: '50vh'
  }

  return (
<div className={`${layout ? 'container-fluid' : 'container'}`}>
<div className="row">
<div className="col-md-12 mb-3">

<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
  <div className="container-fluid">
    <a className="navbar-brand" href="#">Detalhes</a>
    <div className='d-flex gap-3'>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    {onClose && 
              <button id="close" className="btn btn-danger navbar-toggler" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" onClick={(e) => onClose(e)}>X</button>
    }
    </div>
    <div className="collapse navbar-collapse" id="navbarScroll">
      <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" style={{bsScrollHeight: '100px'}}>
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="#">Site: {photo.id}</a>
        </li>
        <li className="nav-item">
          {photo.date && (
            <a className="nav-link" href="#">Data: { moment(`${photo.date}`).format('DD/MM/YYYY HH:mm') }</a>
          )}                    
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Conheça também
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
            <li><a className="dropdown-item" href="https://mikagame.com.br/" target="_blank">Mikagame</a></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item" href="https://comendus.com.br/" target="_blank">Comendus</a></li>
          </ul>
        </li>
      </ul>
      <div className="d-flex">


              <div className="btn-group mx-1">
               <button type="button" onClick={(e) => window.open(photo.page,"_blank")} className="btn btn-secondary"><i className="fa fa-link"></i></button>
              </div>

              <div className="btn-group mx-1">
               <button type="button" onClick={(e) => updatePageG(e)} className="btn btn-secondary"><i className="fa fa-refresh"></i></button>
              </div>

              <div className="btn-group mx-1">
               <button type="button" onClick={(e) => setLike2(e,photo.id)} className="btn btn-secondary"><i className="fa fa-hand-pointer-o"></i> Curtir</button>
              </div>

              {onClose && 
              <button id="close" onClick={(e) => onClose(e)} className="btn btn-danger d-none d-md-block">X</button>
              }

      </div>
    </div>
  </div>
</nav>



  <div className={`${styles.photo} ${layout ? styles.land : ''} ${single ? styles.single : ''}`}>



      <div className={`${styles.img}`}>

          {iframe ? (
            <iframe id="page" src={iframelink} style={styleiframe} allow="camera;microphone" title="Iframe"></iframe>
          )
          :
		      <Magnifier src={photo.src} className={`${styles.mag}`} />
          }

      </div>



    </div>

    </div>
    </div>
    </div>
  );
};

export default PhotoContentSiteMax;
