import React from 'react';
import { appSocket } from '../../config/App'
import { Routes, Route } from 'react-router-dom';
import { UserContext } from '../../UserContext';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Courses.module.css';
import CoursesHeader from './CoursesHeader';
import CoursesAside from './CoursesAside';
import { toastr } from 'react-redux-toastr'
import '../../common/template/dependencies';
import loader from '../../utils/sounds';
//import Messages from '../../common/msg/messages'
import { Link } from 'react-router-dom';
import { useLocation, useSearchParams } from 'react-router-dom';
import InputChat from '../Forms/inputchat';
import useForm from '../../Hooks/useForm';
import Emojis from '../Emojis/Emojis';

const Courses = (props) => {
  const inputchat = useForm();
  const [title, setTitle] = React.useState('');
  const [subtitle, setSubTitle] = React.useState('');
  const [logado, setLogado] = React.useState(false);
  const [modal, showModal] = React.useState(false);
  const [entrou, setEntrou] = React.useState(false);
  const [areaatual, setAA] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [notifica, setNotifica] = React.useState([]);
  const { getArea } = React.useContext(UserContext);
  const location = useLocation();
  //const { data } = React.useContext(UserContext);
  const { data } = useSelector((state) => state.user);
  const areax = useSelector((state) => state.area);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const areaplaylist = useSelector((state) => state.playlistdata);

  var receber = '';
  var historico_mensagens, lista_usuarios = '';

  React.useEffect(() => {

    let desc = searchParams.get("desc");
    if (desc != null) {
      dispatch({ type: 'SETAREA', payload: desc });
      setAA(desc);
    }

  }, [areax]);


  React.useEffect(() => {

    document.querySelector("body").style.padding="0";
    const header = document.getElementById('header');
    header.style.display = 'none'; 
    const footer = document.getElementById('footer');
    footer.style.display = 'none'; 
    toastr.success('Nova mensagem de: ', 'oiiiii');

  }, []);

  React.useEffect(() => {

    appSocket.on("atualizar usuarios", function(usuarios){
      setUsers(usuarios);
    });

  }, []);

  React.useEffect(() => {

    historico_mensagens = document.getElementById('historico_mensagens');
    appSocket.on("atualizar mensagens", (mensagens: MensagemPayload[]) => {

      toque('clique');
      toastr.success('Nova mensagem de: ', mensagens.apelido);

      setNotifica (mensagens);

      var newTH = document.createElement('p');
      if (props.user.nome == mensagens.apelido) {
        newTH.style.color = "white";
      } else {
        newTH.style.color = "yellow";
      }

      if (mensagens.tipo == 'sistema') {
        newTH.style.color = "#17a2b8";
      }

      newTH.style.padding = "0 10px";
      newTH.innerHTML = mensagens.msg;

      if (props.user.nome == mensagens.apelido || mensagens.destino == 'Todos' || mensagens.destino == props.user.nome || mensagens.tipo == 'sistema')
      {
        historico_mensagens.append(newTH);
        historico_mensagens.scrollTop = historico_mensagens.scrollHeight;
      }
    });

  }, []);


  React.useEffect(() => {

    const { pathname } = location;
    switch (pathname) {
      case '/courses/post':
        setTitle('Poste Sua Ideia');
        setSubTitle('Ideias, Lembretes, etc...')
        break;
      case '/courses/stats':
        setTitle('Estatísticas');
        setSubTitle('Estatisticas gerais das postagens');
        break;
      case '/courses/links':
          setTitle('Meus Links');
          setSubTitle('Links diversos que considero importante');
        break;
      case '/courses/playlist':
          setTitle(`Playlist - ${areax}`);
          setSubTitle('Para inserir áudios nesta área basta adicioná-los nas lições na parte de Áudios');
        break;
      case '/courses/area':
          setTitle(`Meus Cursos - ${areax}`);
          setSubTitle('Selecione a etapa, depois o módulo');
        break;
      case '/courses/lessons':
          setTitle(`Meus Cursos - ${areax}`)
          setSubTitle('Sua lição');
        break;
        default:
        setTitle('Minha Conta');
        setSubTitle('Estamos dentro do seu cérebro');
    }
  }, [location,areaatual]);

  function toque(arquivo) {
		var bounceSound = loader.loadSound('audio/' + arquivo);
		bounceSound.play();
		} 

  function enviarNotificacao(e) {

    e.preventDefault();

    var x = document.getElementById("lista_usuarios").selectedIndex;
    var y = document.getElementsByTagName("option");
    var atual = y[x].text;

    var enviar = document.getElementById('texto_mensagem');
    
    if(1==1) {
        appSocket.emit("login", {email: data.email, password: 'senha'}, function(data){
        //console.log(data);
        setLogado(true);

          if (data != null) {

            if(1==1) {
            appSocket.emit("entrar", data.name, function(valido){
              //console.log ('valido' + valido);
              if(valido){
      
                
                setEntrou(true);
                appSocket.emit("enviar mensagem", {
                  origem: data.name,
                  msg: enviar.value,
                  usu: atual
                }, function(data) {
                  //enviar.value = '';
                  inputchat.setValue('');
                  showModal(false);
                });

      
              }else{

                appSocket.emit("enviar mensagem", {
                  origem: data.name,
                  msg: enviar.value,
                  usu: atual
                }, function(data) {
                  //enviar.value = '';
                  inputchat.setValue('');
                  showModal(false);
                  //console.log (data);
                });

              }
            });
          }

          }
      
        });
      } else {
        
        

      }

  }

  function handleFocus(e) {
    e.preventDefault();
    try {
      e.target.focus();
    } 
    catch(err) {
      console.log(err);
    }
    } 

  function handleClique(e) {
    e.preventDefault();
    try {
      showModal(false);
    } 
    catch(err) {
      console.log(err);
    }
    } 

  function handleSubmit(event) {
    event.preventDefault();
    try {
      showModal(!modal);
      console.log('mostra');
    } 
    catch(err) {
      console.log(err);
    }
    } 

  function sendMessage(e) {
    inputchat.setValue(inputchat.value + e.target.innerHTML);
    //showModal(!modal);
  }

  return (

<div className="wrapper">


    <CoursesHeader notifica={notifica}/>
    <CoursesAside user={props.user}/>

    <div className="content-wrapper">

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>{title}</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">{title}</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">

            <div className="card">
              <div className="card-header">
                <h3 className="card-title">{subtitle}</h3>

                <div className="card-tools">
                  <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                    <i className="fas fa-minus"></i>
                  </button>
                  <button type="button" className="btn btn-tool" data-card-widget="remove" title="Remove">
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
              <div className="card-body" style={{padding: '0'}}>
              {props.children}

              </div>
              <div className="card-footer" style={{padding: '0'}}>
                
              </div>
            </div>

          </section>
        </div>


        <footer className="main-footer">
    <div className="float-right d-none d-sm-block">
      <b>Versão</b> 3.2.0
    </div>
    <strong>Copyright &copy; 2023 MyBrain.</strong> Todos os direitos reservados.
  </footer>

  <aside className={`control-sidebar control-sidebar-dark ${styles.asidex}`} style={{height: 'calc(100vh - 70px)', position: 'fixed', top : '55px'}}>

  <div id='sala_chat' style={{ display: 'grid', justifyItems: 'stretch', height: 'calc(100% - 5px)',  gridTemplateRows: '1fr auto', gridTemplateColumns: '1fr'}}>
    <div id="historico_mensagens" style={{ padding: '10px 0', overflowY: 'auto', height: '100%', width: '100%' }}>

  {modal && <div style={{fontFamily: 'Segoe UI Emoji', fontSize: '1.5em', cursor : 'pointer', top : 0, position: 'absolute', padding : '10px', overflowY : 'auto', height: 'calc(50vh - 1em)', backgroundColor : 'bisque', borderRadius: '10px', margin: '10px'}}>
                {Emojis.map((item,i) => (
                  <span key={i} onClick={(e) => sendMessage(e)}>{item}</span>
                ))}
            </div>}
    </div>
    <form id='chat' autoComplete="off" onSubmit={(e) => enviarNotificacao(e)} style={{ padding: '10px'}}>
      <InputChat label="Input" Focus={handleFocus} Clique={handleClique} Procurar={handleSubmit} id="texto_mensagem" name="texto_mensagem" type="text" {...inputchat} style={{ width: '100%', marginBottom: '5px'}}/>
      <select className="form-control" style={{ width: '100%', marginBottom: '5px'}}  id='lista_usuarios'>
        <option value=''>Todos</option>
        {users.map((item,i) => (
          <option key={i} value={item}>{item}</option>
        ))}
      </select>
      <button className="btn btn-primary" style={{ width: '100%'}} type="submit">Enviar!</button>
    </form>
  </div>
  </aside>

</div>    

  );
};

interface MensagemPayload {
  nome: string;
  mensagem: string;
}

export default Courses;
