import React from 'react';
import FeedModal from '../Feed/FeedModalSiteMax';
import { useDispatch, useSelector } from 'react-redux';
import { loadNewPhotos, loadNewPhotosInicial, removePhotos } from '../../store/sites';
import SitesContent from './SitesContent';
import useForm from '../../Hooks/useForm';

const Sites = (props) => {
  const { list, infinite, pages } = useSelector((state) => state.sites);
  const dispatch = useDispatch();
  const [modalPhoto, setModalPhoto] = React.useState(null);




  React.useEffect(() => {
    dispatch(removePhotos());
    dispatch(loadNewPhotos(props.area_id));
  }, [dispatch]);

  React.useEffect(() => {
    let wait = false;
    function infiniteScroll() {
      if (infinite) {
        const scroll = window.scrollY;
        const height = document.body.offsetHeight - window.innerHeight;
        if (scroll > height * 0.75 && !wait) {
          dispatch(loadNewPhotos(props.area_id));
          wait = true;
          setTimeout(() => {
            wait = false;
          }, 500);
        }
      }
    }
  
    window.addEventListener('wheel', infiniteScroll);
    window.addEventListener('scroll', infiniteScroll);
    return () => {
      window.removeEventListener('wheel', infiniteScroll);
      window.removeEventListener('scroll', infiniteScroll);
    };
  }, [infinite, dispatch]);

    
  return (
    <>
      {modalPhoto && (
        <FeedModal photo={modalPhoto} setModalPhoto={setModalPhoto} />
      )}
      {list.length > 0 && <SitesContent setModalPhoto={setModalPhoto} />}
      
    </>
  );
};

export default Sites;
