import React from 'react';
import styles from './PhotoUpdate.module.css';
import { PAGE_UPDATE } from '../../Api';
import useFetch from '../../Hooks/useFetch';

const PhotoUpdate = ({ id, html, css, js, atualizar }) => {
  const { loading, request } = useFetch();

  async function handleClick() {

      const formData = new FormData();
    
      formData.append('html', html);
      formData.append('css', css);
      formData.append('js', js);

      const { url, options } = PAGE_UPDATE(formData, id);
      const { response } = await request(url, options);
      if (response.ok) atualizar();
  }

  return (
    <>
      {loading ? (
        <button className={styles.update} disabled>
          Atualizar
        </button>
      ) : (
        <button onClick={handleClick} className={styles.update}>
          Atualizar
        </button>
      )}
    </>
  );
};

export default PhotoUpdate;
