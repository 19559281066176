import { combineReducers } from '@reduxjs/toolkit';
import createAsyncSlice from './helper/createAsyncSlice';
import getLocalStorage from './helper/getLocalStorage';
import { removePhotos } from './photos';
import { API_URL } from '../config/App';

const token = createAsyncSlice({
  name: 'token',
  initialState: {
    data: {
      token: getLocalStorage('token', null),
    },
  },
  reducers: {
    removeToken(state) {
      state.data = null;
    },    
    fetchSuccess: {
      reducer(state, action) {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      },
      prepare(payload) {
        return {
          payload,
          meta: {
            localStorage: {
              key: 'token',
              value: payload.token,
            },
          },
        };
      },
    },
  },
  fetchConfig: (user) => ({
    url: API_URL + '/loginjwt',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    },
  }),
});

const user = createAsyncSlice({
  name: 'user',
  reducers: {
    removeUser(state) {
      state.data = null;
    },
  },
  fetchConfig: (token) => ({
    url: API_URL + '/club/user',
    options: {
      method: 'GET',
      headers: {
        'Accept' : 'application/json',
        'Content-Type': 'application/json',        
        Authorization: 'Bearer ' + token,
      },
    },
  }),
});
const reducer = combineReducers({ token: token.reducer, user: user.reducer });

const fetchToken = token.asyncAction;
const fetchUser = user.asyncAction;

const { removeToken } = token.actions;
const { removeUser } = user.actions;

export default reducer;

export const login = (user) => async (dispatch) => {
  try {
    const { payload } = await dispatch(fetchToken(user));
    if (payload !== undefined) {
      window.localStorage.setItem('token', payload.token);
      const resultado = await dispatch(fetchUser(payload.token));
      console.log('resultado');
      console.log(resultado);
    }
  } catch (err) {
    console.log(err);
  }
};

export const autoLogin = () => async (dispatch, getState) => {
  const state = getState();
  console.log(state);
  const token2 = window.localStorage.getItem('token');
  //const { token } = state.token.data;
  if (token2) {
    const resultado = await dispatch(fetchUser(token2));
    console.log ('autologin');
    console.log (resultado);
  }

};

export const userLogout = () => (dispatch) => {
  dispatch(removeUser());
  dispatch(removeToken());
  dispatch(removePhotos());
  window.localStorage.removeItem('token');
};