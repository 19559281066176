import React, { Component, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link, useNavigate } from 'react-router-dom';
import { reduxForm, Field, initialize, arrayInsert, arrayRemove, formValueSelector } from 'redux-form'
import MaskedInput from 'react-text-mask'
import LabelAndInput from '../../common/form/labelAndInput'
import LabelAndInputMask from '../../common/form/labelAndInputMask'
import Avatar from "avataaars"
import { updateUser, getData2 } from '../../actions/generalActions'
import { autoLogin } from '../../store/login';

class UserData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            times: 0,
            avatarStyle: 'Circle',
            topType: 'LongHairBob',
            accessoriesType: 'Blank',
            hairColor: 'Brown',
            facialHairType: 'Blank',
            clotheType: 'ShirtCrewNeck',
            clotheColor: 'Heather',
            eyeType: 'Side',
            eyebrowType: 'Default',
            mouthType: 'Twinkle',
            skinColor: 'Light'             
        };    
      }

      componentDidUpdate(prevProps,prevState) {

        if (prevState.times >= this.state.times) {
          this.setState({
              times: this.state.times + 1
          });
        }

      }

      componentWillMount() {
        
        console.log ('varrrrrrrrrrrrrrrrrrrrrrr')
        console.log(this.props.avatarx.data);

        initialize('userData', this.props.getData2())
        //self.props.getData2();

      //  var self = this;
      //  setTimeout(function() {
      //  }, 1500);

      var self = this;
        //setTimeout(function() {
            var todos = self.props.avatarx.data.avatar;
            self.setState (JSON.parse(todos));
        //}, 1000);

    }


    render() {

        const renderFields = (fields) => (
            <input {...fields.name.input} type="text"/>
          )

        const verisso = () => {
            return (<hr/>)
        }

        const { handleSubmit, readOnly} = this.props

        //console.log (name);

        const handleAvatar = () => {
            handleSubmit();
            var self = this; 
            setTimeout(function() {
                self.props.setAvatar();
            }, 1500);
          }

        const TextMaskCustom = (props) => {
          const { inputRef, ...other } = this.props;
          let mask = [];
      
          if (props.id === 'cardNumber') {
              mask = [/[0-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]
          }
      
          if (props.id === 'cardExpiration') {
              mask = [/[0-9]/, /\d/, '/', /\d/, /\d/];
          }
      
          if (props.id === 'securityCode') {
              mask = [/[0-9]/, /\d/, /\d/];
          }
      
          if (props.id === 'cnpj') {
              mask = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
          }
      
          if (props.id === 'mobile_phone') {
              mask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
          }

          return (
            <MaskedInput
                {...other}
                ref={ref => {
                    inputRef(ref ? ref.inputElement : null)
                }}
                mask={mask}
                guide={false}
            />
        )

        }

        const selector = formValueSelector('userData')
        return (

            <form onSubmit={handleSubmit((values)=> this.props.updateUser(values, 999))} role='form'>
                <div className='container'>
                <div className='row'>
                <div className='col-md-6'>

                <div className='form-group' style={{ textAlign:'center' }}>
                <Link to="/courses/perfil" className="nav-link">
                <Avatar
                     {...this.state}
                />
                </Link>
                </div>
                </div>

                <div className='col-md-6'>

                <Field name='name' component={LabelAndInput} readOnly={readOnly}
                        label='Nome' cols='12 4' placeholder='Informe o nome' />

                <Field name='username' component={LabelAndInput} readOnly={readOnly}
                        label='UserName' cols='12 4' placeholder='Informe o username' />

                <Field name='email' component={LabelAndInput} readOnly={true}
                        label='E-mail' cols='12 4' placeholder='Informe o e-mail' />

                <Field name='points' component={LabelAndInput} readOnly={readOnly}
                        label='Pontos' cols='12 4' placeholder='Informe os pontos' type='number' />


                </div>

                <div className='col-md-12'>
                        <button type='submit' className='btn btn-primary pull-right'>
                            Salvar <span className='badge badge-light'>{this.state.times}</span>
                        </button>
                </div>

                </div>
                </div>

            </form>
        )
    }
}

UserData = reduxForm({form: 'userData', destroyOnUnmount: false})(UserData)
const mapStateToProps = state => ({avatarx: state.user})
const mapDispatchToProps = dispatch => bindActionCreators({updateUser, getData2, autoLogin}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(UserData)
