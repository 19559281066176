import React from 'react';
import axios from 'axios';
import ReactModal from 'react-modal';
import IdeaItem from './IdeaItem';
import UploadApp from './UploadApp';
import UploadAppG from './UploadAppG';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Loading } from '../Assets/loading.svg';
import swal from 'sweetalert';
import { fetchSite } from '../store/site';
import { loadSnippets, removePhotos } from '../store/photos';
import { API_URL } from '../config/App';

const SnippetsApp = (props) => {

  var [ideas, setIdeas] = React.useState([]);
  var items = [];
  var send = [];
  const [modal, setModal] = React.useState(false);
  const { loading, error, data } = useSelector((state) => state.site);
  const ideasnovas = useSelector((state) => state.photos);
  const dispatch = useDispatch();
  const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        width: '90%',
        height: '80%',
        bottom: 'auto',
        marginRight: '-50%',
        border: '4px solid #ff6550',
        borderRadius: '5px',
        transform: 'translate(-50%, -50%)',
        marginTop: '25px'
    },
    };       

    React.useEffect(() => {
      dispatch(removePhotos());
    }, []);

    React.useEffect(() => {
      setIdeas(data?.ideas);
    }, [loading]);

    function handleOpenModal (event) {
        event.preventDefault();
        setModal(true);
       dispatch(fetchSite(props.id));
       dispatch(loadSnippets());
    }
    
    function handleCloseModal (event) {
      var aqui = this;
      var modal = document.getElementById("Modal");
      //var modal = document.querySelectorAll('[overlayClassName]');
      event.preventDefault()

      modal.parentNode.classList.add("bounce-out-bottom");
      //event.target.parentNode.parentNode.parentNode.classList.add("bounce-out-top");
        setTimeout(function(){ 
          setModal(false);
          //setLoading(false);
        }, 2000);
        //this.setState({ showModal: false });
      }

      function handleOnDragEnd  (result) {
        if (!result.destination) return;
    
        items = Array.from(ideas);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        
        setIdeas(items);
    
        let ideias = {};
        items.forEach(function(item) {
          send.push({'id' : item['id']});
        });
       
    
        setOrder(props.id,send);
      }
    
      function AddSnippet (id) {
  
              axios ({
                  method: 'POST',
                  url: `${API_URL}/club/site/block/${id}`,
                  data: {'id': props.id}
              }).then(function (response) {
                  
                dispatch(fetchSite(props.id));
                console.log(response);
        
              }).catch(function (error) {
                  console.log('Error: ', error);
              });
      
      }

      function setDeletar (id) {
        var self = this;
        swal({
          title: `Deseja realmente apagar o registro ${id} ?`,
          text: "Depois de deletado, você não poderá recuperar esse registro novamente!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
          })
          .then((willDelete) => {
          if (willDelete) {
  
            var fecha = this;
  
                axios ({
                  method: 'DELETE',
                  url: `${API_URL}/club/site/block/${id}`,
                  data: {'id':id}
              }).then(function (response) {
                  
                dispatch(fetchSite(props.id));
        
              }).catch(function (error) {
                  console.log('Error: ', error);
              });
  
          } else {
              //swal("Your imaginary file is safe!");
          }
          });
    
      }
  
      function setOrder (id,ideas) {
    
        axios ({
            method: 'POST',
            url: `${API_URL}/club/site/order/${id}`,
            data: {'id':id,'ideas':ideas}
        }).then(function (response) {
            console.log(response);
        }).catch(function (error) {
            console.log('Error: ', error);
        });
      }

            
        return (
        <div>
 
            <div className="btn-group" role="group" aria-label="Comandos">
              <button className="btn btn-primary m-0 slide-in-fwd-center" onClick={(e) => handleOpenModal(e)}><i className="fas fa-address-card" aria-hidden="true"></i></button>
            </div>

            <ReactModal id="Modal" className="Modal2" style={customStyles} ariaHideApp={false} overlayClassName="Overlay2 bounce-in-top"
            isOpen={modal}
            contentLabel="Minimal Modal Example"
            >
            <nav className="navbar navbar-expand-sm bg-dark navbar-dark">
            <div className="container-fluid">
                <div className="navbar-header">
                <div className="navbar-brand"><i className="fa fa-fw fa-image"></i> Banco de Ideias</div>
                </div>

                    <button className="btn btn-danger btn-sm m-3 float-right" onClick={handleCloseModal}>X</button>


            </div>
            </nav>

            <div className="area"> 
            <div className="container">
                <div className="row">

               <div className="col-md-6">
               {loading && <Loading />}

                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="characters">
                    {(provided) => (
                      <ul className="characters" style={{ padding: 0, margin: 0, overflowY: 'auto', paddingBottom: '20px'}} {...provided.droppableProps} ref={provided.innerRef}>

                        {ideas && ideas.map(({id, description, slug}, index) => {
                          return (
                            <Draggable key={id} draggableId={`C${id}`} index={index}>
                              {(provided) => (
                                <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} key={index} color="#424242">
                                <button className="btn btn-danger" onClick={() => setDeletar(id)} style={{ padding: '5px', marginRight: '5px'}}>-</button>
                                {description}
                                </li>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
                </div>

                <div className="col-md-6">
                 
                    <ul className="characters" style={{ padding: 0, margin: 0, overflowY: 'auto', paddingBottom: '20px'}}>
                    {ideasnovas && ideasnovas.list?.map(({id, title, slug}, index) => {
                          return (
                            
                                <li key={index} color="#424242">
                                <button className="btn btn-primary" onClick={() => AddSnippet(id)} style={{ padding: '5px', marginRight: '5px'}}>+</button>
                                <p>{title}</p>
                                </li>
                             
                          );
                        })}
                    </ul>

                </div>


                </div>
            </div>

            </div>

      </ReactModal>
        </div>
        );
    }
    

    export default SnippetsApp;