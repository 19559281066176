import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadNewPhotos } from '../../store/photos';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useFetch from '../../Hooks/useFetch';
import { GRUPOS_GET, SUBGRUPOS_GET } from '../../Api';
import Modulos from './Modulos';
import axios from 'axios';
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import swal from 'sweetalert';
import fileDownload from 'js-file-download';
import { BASE_URL, API_URL } from '../../config/App';

const Playlist = (props) => {
  const { data } = useSelector((state) => state.user);
  const {request, datax } = useFetch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [audios, setAudios] = React.useState([]);
  const [urlimg, setUrlimg] = React.useState(`${BASE_URL}storage/`);
  const [trackIndex, setTIndex] = React.useState(0);
  const [currentTrack, setCurrentTrack] = React.useState(0);
  const [type, setType] = React.useState();
  const dispatch = useDispatch();
  const player = React.useRef(null);
  const areaplaylist = useSelector((state) => state.playlistdata);

  React.useEffect(() => {
    getList(data.id,areaplaylist);
    //this.getList(this.props.UserId,this.props.LanguageId);
    player.current.audio.current.pause();
  }, []);


  function setAtualizar (event) {
    var fecha = this;
    axios.get(`${this.props.UrlSite}api/lessons/getimages/${this.props.ProductID}?type=${type}`)
    .then(res => {
        fecha.setState({ 
            loading: false, 
            imagens: res.data,
        })
    })
}

function setAtualizarParam (type) {
  var fecha = this;
  axios.get(`${this.props.UrlSite}api/lessons/getimages/${this.props.ProductID}?type=${type}`)
  .then(res => {
      fecha.setState({
          loading: false, 
          imagens: res.data,
      })
  })
}

function getList (id,lang) {

  var _self = this;
  axios ({
      method: 'POST',
      url: `${API_URL}/lessons/getplaylist`,
      data: {'user_id':id,'language_id':lang}
  }).then(function (response) {
      //swal(`Esse arquivo foi ordenado para: ${ordem}`, {
      //    icon: "success",
      //    timer: 1000
      //    });

      setAudios(response.data);
      player.current.audio.current.pause();
      //_self.setState({ audios: response.data });
      //_self.player.current.audio.current.pause();
      //console.log(_self.state.audios);

  }).catch(function (error) {
      console.log('Error: ', error);
  });
}


  function setDeletar (item) {
    //event.preventDefault();
    //var target = event.target;
    var self = this;
    swal({
      title: "Deseja realmente apagar o registro ?",
      text: "Depois de deletado, você não poderá recuperar esse registro novamente!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then((willDelete) => {
      if (willDelete) {

        var fecha = this;

            axios ({
              method: 'POST',
              url: `${API_URL}/lessons/removeplaylist`,
              data: {'user_id':item.user_id, 'language_id':item.language_id, 'lesson_id':item.lesson_id,'lesson_photos':item.lesson_photos}
          }).then(function (response) {
              
            getList(data.id,areaplaylist);
            //self.setAtualizar();
    
          }).catch(function (error) {
              console.log('Error: ', error);
          });

      } else {
          //swal("Your imaginary file is safe!");
      }
      });

  }


  function setarAudioAtual (e,audio,i) {
      
    var span = e.target.getElementsByTagName("span");

    setTIndex(i);   

    if (player.current.isPlaying()) {
      player.current.audio.current.pause();
      span[0].classList.remove("fa-play");
      span[0].classList.add("fa-pause");
    } else {
      player.current.audio.current.play();
      span[0].classList.add("fa-play");
      span[0].classList.remove("fa-pause");
    }
    //console.log();


    //this.player.current.audio.current.pause();
    //this.player.current.innerText = audio.image;
    //this.player.current.audio.current.attributes[0].value = this.state.urlimg + audio.image;
    //this.player.current.audio.current.play();
  }


  function setTrackIndex (currentTrack) {
    setCurrentTrack(currentTrack);
    console.log (currentTrack)
  };

  function handleClickPrevious () {
    var current = trackIndex === 0 ? audios.length - 1 : trackIndex - 1;
    setTIndex(current);
  };

  function handleClickNext () {
    var current = trackIndex < audios.length - 1 ? trackIndex + 1 : 0
    setTIndex(current);
  };

  function handleClickEnd () {
    var current = trackIndex < audios.length - 1 ? trackIndex + 1 : 0
    setTIndex(current);
    if (trackIndex == audios.length - 1) {
      console.log('acabou');
      player.current.audio.current.pause();
    }
  };

  function handleDownload (url, filename) {
    axios.get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename)
    })
  }

  const renderTodos = audios.map((item, i) => {
    return  (<div key={i} style={{ display: 'grid',
      gridTemplateColumns: 'auto 2fr auto auto auto', width: '90%', margin: '0 auto' }}>
      <p className="text-secundary m-1 p-1">{ item.id }</p>
      <p className="text-primary m-1 p-1">{ item.description }</p>
      <button onClick={(e) => setarAudioAtual(e,item,i)} className="btn btn-primary m-1 p-1"><span style={{pointerEvents : 'none'}} className="fa fa-fw fa-play"></span></button>
      <button onClick={() => setDeletar(item)} className="btn btn-danger m-1 p-1"><span style={{pointerEvents : 'none'}} className="fa fa-fw fa-trash"></span></button>
      <a href={urlimg + item.image}className="btn btn-info m-1 p-1" rel="noopener noreferrer" download target="_blank"><span className="fa fa-fw fa-download" ></span></a>
    </div>)
  });

return (
  <div>

    <AudioPlayer ref={player}
            // style={{ width: "300px" }}
            style={{ borderRadius: "1rem" , padding: '30px', margin: '20px auto', maxWidth:'90%' }}
            autoPlay={false}
            // layout="horizontal"
            src={audios.length && urlimg + audios[trackIndex].image}
            onPlay={(e) => console.log("onPlay")}
            showSkipControls={true}
            showJumpControls={true}
            header={audios.length && `Agora tocando:
            ${audios[trackIndex].description}`}
            footer="MyBrain"
            onClickPrevious={handleClickPrevious}
            onClickNext={handleClickNext}
            onEnded={handleClickEnd}
            // other props here
          />
      
      <div>
      { renderTodos}
      </div>

  </div>
  );

};

export default Playlist;

