import React from 'react';
import FeedModal from './FeedModal';
import FeedPhotosBig from './FeedPhotosBig';
import PropTypes from 'prop-types';
import imagesLoaded from 'imagesloaded'; 

const FeedBig = ({ user }) => {
  const [modalPhoto, setModalPhoto] = React.useState(null);
  const [pages, setPages] = React.useState([1]);
  const [infinite, setInfinite] = React.useState(true);
  var grid, rowHeight, rowGap, rowSpan, allItems, item  = '';

  React.useEffect(() => {
    //window.onload = resizeAllGridItems();
    //setTimeout(inicia,2000);
  }, []);

  function inicia() {
    grid = document.getElementsByClassName("grid")[0];
    grid.classList.add("gridAdd");
    resizeAllGridItems();
  }

  React.useEffect(() => {
    let wait = false;
    function infiniteScroll() {
      if (infinite) {
        const scroll = window.scrollY;
        const height = document.body.offsetHeight - window.innerHeight;
        if (scroll > height * 0.75 && !wait) {
          setPages((pages) => [...pages, pages.length + 1]);
          wait = true;
          setTimeout(() => {
            wait = false;
          }, 500);
        }
      }
    }

    window.addEventListener('wheel', infiniteScroll);
    window.addEventListener('scroll', infiniteScroll);
    return () => {
      window.removeEventListener('wheel', infiniteScroll);
      window.removeEventListener('scroll', infiniteScroll);
    };
  }, [infinite]);

  function resizeGridItem(item){
    grid = document.getElementsByClassName("grid")[0];
    rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
    rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
    rowSpan = Math.ceil((item.querySelector('.content').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
      item.style.gridRowEnd = "span "+rowSpan;
  }
  
  function resizeAllGridItems(){
    allItems = document.getElementsByClassName("item");
    //console.log(allItems);
    for(let x=0;x<allItems.length;x++){
      resizeGridItem(allItems[x]);
    }
  }
  
  function resizeInstance(instance){
    item = instance.elements[0];
    resizeGridItem(item);
  }
  
  window.addEventListener("resize", resizeAllGridItems);
  
  allItems = document.getElementsByClassName("item");
  for(let x=0;x<allItems.length;x++){
    imagesLoaded( allItems[x], resizeInstance);
  }	

  return (
    <>
      {modalPhoto && (
        <FeedModal photo={modalPhoto} setModalPhoto={setModalPhoto} />
      )}
      {pages.map((page) => (
        <FeedPhotosBig
          key={page}
          user={user}
          page={page}
          setModalPhoto={setModalPhoto}
          setInfinite={setInfinite}
        />
      ))}
      {!infinite && !user && (
        <div
          style={{
            textAlign: 'center',
            padding: '4rem',
            color: '#f9f9f9',
            display: 'block',
            position: 'relative',
            width: '100%',
            minHeight: '3rem'
          }}
        >
          Não existem mais postagens.
        </div>
      )}
    </>
  );
};

FeedBig.defaultProps = {
  user: 0,
};

FeedBig.propTypes = {
  user: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
};

export default FeedBig;
