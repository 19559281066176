import React from 'react';
import { UserContext } from '../../UserContext';
import styles from './PhotoComments.module.css';

const PhotoFiles = (props) => {
  const [files, setFiles] = React.useState(() => props.files);
  const commentsSection = React.useRef(null);
  const { login } = React.useContext(UserContext);

  React.useEffect(() => {
    commentsSection.current.scrollTop = commentsSection.current.scrollHeight;
    //console.log ('comentariox');
    //console.log(props.comments);
    //props.comments = JSON.parse(props.comments); 
  }, [files]);

  return (
    <div style={{ backgroundColor : '#f2f2f2'}} >
      <ul
        ref={commentsSection}
        className={`${styles.comments} ${props.single ? styles.single : ''}`}
        style={{ display: 'flex', flexWrap: 'wrap' , listStyle: 'none', padding: '10px'}}
      >
        
        {files && files.map((file,index) => (
          <li key={index}>
            <img className='img-fluid' onClick={() => props.abrirImg(file.imagem)} src={file.imagem}/>
          </li>
        ))}

      </ul>
    </div>
  );
};

export default PhotoFiles;
