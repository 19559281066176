import { SITES_GET } from '../Api';
import createAsyncSlice from './helper/createAsyncSlice';

const slice = createAsyncSlice({
  name: 'feedsites',
  initialState: {
    list: [],
    pages: 1,
    avatar: [],
    userfeed: {},
    links: [],
    category: 0,
    infinite: true,
  },
  reducers: {
    addPhotos(state, action) {
      state.list.push(...action.payload.data);
      state.userfeed = {...action.payload.user};
      state.avatar = [{...action.payload.avatar}];
      state.links = [...action.payload.meta.links];
      if (action.payload.length === 0) state.infinite = false;
    },
    searchbyLinks(state, action) {

      console.log('em links');
      const data = action.payload.data;
      const bylinks = data?.filter(({ link }) => link != null);
      state.pages = 0;
      state.list.push(...bylinks);
      state.userfeed = {...action.payload.user};
      state.avatar = [{...action.payload.avatar}];
      state.links = [...action.payload.meta.links];
      //if (action.payload.length === 0) state.infinite = false;
      state.infinite = false;
    },
    searchbyVideos(state, action) {

      console.log('em videos');
      const data = action.payload.data;
      const byvideos = data?.filter(({ video }) => video != null);
      state.pages = 0;
      state.list.push(...byvideos);
      state.userfeed = {...action.payload.user};
      state.avatar = [{...action.payload.avatar}];
      state.links = [...action.payload.meta.links];
      //if (action.payload.length === 0) state.infinite = false;
      state.infinite = false;
    },
    addPage(state) {
      state.pages++;
    },
    setCategory(state,cat) {
      state.category = cat.payload;
    },    
    disableInfinite(state) {
      state.infinite = false;
    },    
    setPage(state,page) {
      state.pages = page;
    },
    resetState(state) {
      state.infinite = true;
      state.pages = 1;
      state.list = [];
      state.data = null;
      state.error = null;
      state.loading = false;
    },
  },
  fetchConfig: ({ page, total, user, key, category  }) => SITES_GET({ page, total, user, key, category  }),
});

export const fetchFeed = slice.asyncAction;
export const { addPhotos, addPage, setCategory, disableInfinite, searchbyVideos, searchbyLinks, setPage, resetState: resetFeedState } = slice.actions;

export const loadNewPhotos = ({ total = 8, user, key, links, videos, category }) => async (
  dispatch,
  getState,
) => {
  console.log ('links',links);
  const { feedsites } = getState();
  if (!links || !videos) dispatch(addPage());
  const { payload } = await dispatch(
    fetchFeed({ page: feedsites.pages, total, user, key, category: feedsites.category }),
  );
  if (links) {
    dispatch(searchbyLinks(payload));
  } else if (videos) {
    dispatch(searchbyVideos(payload));
  } else {
  dispatch(addPhotos(payload));
  }
};

export const loadAll = ({ total = 8, user, key, links, videos, category }) => async (
  dispatch,
  getState,
) => {
  console.log ('links',links);
  const { feedsites } = getState();
  if (!links || !videos) dispatch(addPage());
  const { payload } = await dispatch(
    fetchFeed({ page: feedsites.pages, total, user, key, category: feedsites.category }),
  );
  if (links) {
    dispatch(searchbyLinks(payload));
  } else if (videos) {
    dispatch(searchbyVideos(payload));
  } else {
  dispatch(addPhotos(payload));
  }
};

export const loadPhotos = ({ page = 1, total = 8, user, key }) => async (
  dispatch,
  getState,
) => {
  const { feedsites } = getState();
  dispatch(resetFeedState());
  dispatch(setPage(feedsites,page));
  const { payload } = await dispatch(
    fetchFeed({ page, total, user, key }),
  );
  dispatch(addPhotos(payload));
};


export const searchPhotos = ({ key, user = 0, total = 8 }) => async (
  dispatch,
  getState,
) => {
  const { feedsites } = getState();
  dispatch(resetFeedState());
  dispatch(addPage());
  dispatch(disableInfinite());  
  const { payload } = await dispatch(
    fetchFeed({ page: 1, total, user, key }),
  );
  dispatch(addPhotos(payload));
};

export default slice.reducer;
