import React from 'react';
import axios from 'axios';
import ReactModal from 'react-modal';
import ImagemItem from './ImagemItem';
import UploadApp from './UploadApp';
import UploadAppG from './UploadAppG';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ReactComponent as Loading } from '../Assets/loading.svg';
import swal from 'sweetalert';

//import "bootstrap/dist/css/bootstrap.min.css";

export default class ImagensApp extends React.Component {
    constructor (props) {
        super();
        this.state = {
        titulo: 'Banco de Arquivos',
        imagens: [],
        type: 1,
        loading: false,
        status: false,
        currentPage: 1,
        todosPerPage: 4,        
        showModal: false
        };
        //const { data, loading, error, request } = useFetch();

        this.pageNumbers = [];
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleOpenModal2 = this.handleOpenModal2.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }


    handleOpenModal (event) {
        console.log(this.props.ProductID);
        event.preventDefault();
        this.setState({ showModal: true, loading: true, type: 1 });
        this.setAtualizarParam(1);
    }

    handleOpenModal2 (event) {
      event.preventDefault();
      this.setState({ showModal: true, loading: true, type: 2 });
      this.setAtualizarParam(2);
    }

    
    setOrdenar = (event) => {
      let self = this;

      if (this.state.status) {
        this.state.imagens.forEach(function(value, i) {
          let cod = value.id;
          console.log(cod);
          self.setOrder(cod,i);
        })
  
      }

      this.setState({
        status: false
      });

    }

    handleCloseModal (event) {
      var aqui = this;
      var modal = document.getElementById("Modal");
      //var modal = document.querySelectorAll('[overlayClassName]');
      event.preventDefault()

      this.setOrdenar(event);
      //modal.document.querySelectorAll('[myAttribute]');
      //modal.classList.add("Overlay");
      //modal.classList.add("bounce-out-top");
      //var modalx = modal.getAttribute('class');
      //console.log(modalx);
      modal.parentNode.classList.add("bounce-out-bottom");
      //event.target.parentNode.parentNode.parentNode.classList.add("bounce-out-top");
        setTimeout(function(){ aqui.setState({ showModal: false, loading: false }); }, 2000);
        //this.setState({ showModal: false });
      }

    handleChecar (event) {
      console.log ('fechou');
    }

    setLast = (event) => {

       if (this.pageNumbers.length !== 0) {
    
        this.setState({ 
                currentPage: Number(this.pageNumbers[this.pageNumbers.length-1])
        })
      }

    }

    setTitulo = (titulotemp) => {
      this.setState({ 
        titulo: titulotemp,
      })
    }

    setDeletar = (id) => {
      //event.preventDefault();
      //var target = event.target;
      var self = this;
      console.log (self.props.UrlSite);
      swal({
        title: "Deseja realmente apagar o registro ?",
        text: "Depois de deletado, você não poderá recuperar esse registro novamente!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        })
        .then((willDelete) => {
        if (willDelete) {

          var fecha = this;

              axios ({
                method: 'POST',
                url: `${this.props.UrlSite}api/files/image/delete/${id}`,
                data: {'id':id}
            }).then(function (response) {
                
              self.setAtualizar();
      
            }).catch(function (error) {
                console.log('Error: ', error);
            });

        } else {
            //swal("Your imaginary file is safe!");
        }
        });
  
    }

    setAtualizar = (event) => {
        console.log('atualizar');
        var fecha = this;
        axios.get(`${this.props.UrlSite}api/files/getimages/${this.props.ProductID}?type=${this.state.type}`)
        .then(res => {
            fecha.setState({ 
                loading: false, 
                imagens: res.data,
            })
        })
    }

    setAtualizarParam = (type) => {
      var fecha = this;
      axios.get(`${this.props.UrlSite}api/files/getimages/${this.props.ProductID}?type=${type}`)
      .then(res => {
          fecha.setState({
              loading: false, 
              imagens: res.data,
          })
      })
    }

    setOrder = (id,ordem) => {

      axios ({
          method: 'POST',
          url: `${this.props.UrlSite}api/files/image/order/${id}`,
          data: {'id':id,'order':ordem}
      }).then(function (response) {
          //swal(`Esse arquivo foi ordenado para: ${ordem}`, {
          //    icon: "success",
          //    timer: 1000
          //    });

      }).catch(function (error) {
          console.log('Error: ', error);
      });
  }

    componentDidMount() {
       // this.setAtualizar();
    }

    componentWillUnmount() {
      //this.setAtualizar();
      //console.log('acabou3'); 

    }

    handleClick = (event) => {
        this.setState({
          currentPage: Number(event.target.id)
        });
      }

    handleOnDragEnd = (result) => {
      if (!result.destination) return;

      const items = Array.from(this.state.imagens);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
  
      this.setState({
        imagens: items,
        status: true
      });

      //this.setOrder(cod,destino-origem)
    }

    render () {

        const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            width: '90%',
            height: '80%',
            bottom: 'auto',
            marginRight: '-50%',
            border: '4px solid #ff6550',
            borderRadius: '5px',
            transform: 'translate(-50%, -50%)',
            marginTop: '25px'
        },
        };       

        const { imagens } = this.state;
            
        return (
        <div>
 
            <div className="btn-group" role="group" aria-label="Comandos">
              <button className="btn btn-primary m-0 slide-in-fwd-center" onClick={(e) => this.handleOpenModal(e)}><i className="fas fa-file-image-o" aria-hidden="true"></i></button>
              <button className="btn btn-primary m-0 slide-in-fwd-center" onClick={(e) => this.handleOpenModal2(e)}><i className="fas fa-file-audio-o" aria-hidden="true"></i></button>
            </div>

            <ReactModal id="Modal" className="Modal2" style={customStyles} ariaHideApp={false} overlayClassName="Overlay2 bounce-in-top"
            isOpen={this.state.showModal}
            contentLabel="Minimal Modal Example"
            >
            <nav className="navbar navbar-expand-sm bg-dark navbar-dark">
            <div className="container-fluid">
                <div className="navbar-header">
                <div className="navbar-brand"><i className="fa fa-fw fa-image"></i> {this.state.titulo} ({imagens.length})</div>
                </div>

                    <UploadApp UrlSite={this.props.UrlSite} ProductID={this.props.ProductID} Titulo={this.setTitulo} Last={this.setLast} Atualizar={this.setAtualizar} idPlat={this.props.idPlat}/>

                    <UploadAppG UrlSite={this.props.UrlSite} ProductID={this.props.ProductID} Titulo={this.setTitulo} Last={this.setLast} Atualizar={this.setAtualizar} idPlat={this.props.idPlat}/>

                    <button className="btn btn-danger btn-sm m-3 float-right" onClick={this.handleCloseModal}>X</button>


            </div>
            </nav>

            <div className="area"> 
            <div className="container">
                <div className="row">

                <DragDropContext onDragEnd={this.handleOnDragEnd}>
                  <Droppable droppableId="characters">
                    {(provided) => (
                      <ul className="characters" {...provided.droppableProps} ref={provided.innerRef}>

                        {this.state.loading && (<Loading />)}

                        {imagens.map(({id, image, description, visibility, type}, index) => {
                          return (
                            <Draggable key={id} draggableId={`C${id}`} index={index}>
                              {(provided) => (
                                <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} key={index} titulo={id} image={image} color="#424242"><ImagemItem UrlSite={this.props.UrlSite} atualizar={this.setAtualizar} deletar={this.setDeletar} item={this.state.imagens[index]} ordenar={this.setOrdenar} description={description} visibility={visibility} type={type} key={index} id={id} image={image} alt='' title='' text=''/>
                                </li>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>

                </div>
            </div>

            </div>

      </ReactModal>
        </div>
        );
    }
    }
