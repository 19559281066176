import React from 'react';
import If from './if';
import styles from './Input.module.css';

const SelectVisible = ({ label, type, name, value, onChange, error, onBlur, invisible }) => {
  if (!invisible) {
  return (
    <div className={styles.wrapper} >
      <label htmlFor={name} className={styles.label}>
        {label}
      </label>
      <select name={name} value={value} id={name}
        onChange={onChange}
        onBlur={onBlur}
        className={styles.input}>
        <option value='1'>Visível</option>
        <option value='2'>Invisível</option>
      </select>
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
} else return null
};

export default SelectVisible;
