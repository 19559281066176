import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadNewPhotos } from '../../store/photos';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useFetch from '../../Hooks/useFetch';
import { NEWS_GET } from '../../Api';
import axios from 'axios';
import XMLParser from 'react-xml-parser';
import parseString from 'xml2js';

const News = () => {
  const {request, data } = useFetch();
  const { id } = useParams();
  const [partida, setPartida] = React.useState('');
  const [retorno, setRetorno] = React.useState('');
  const [noticia, setNoticia] = React.useState(0); 
  const [news, setNews]  = React.useState ([]);

  function atualizanews() {
    setInterval(() => {
      setNoticia( noticia => noticia + 1 );
    }, 6000);
  }

  React.useEffect(() => {
    if (noticia >= news.length) {setNoticia(0)} 
  }, [noticia]);

  React.useEffect(() => {
    async function getData() {

      //console.log('rawFile');
      
      const { url, options } = NEWS_GET();
      const { json } = await request(url, options);
      
      if (json != null) {
        setNews (json);
        atualizanews();
      }

    }
    getData();
  }, []);

  
    return (
      <section className="container">
        <div className='row'>
          <div className='col-md-12'>
              <div className="quadimg cor text-center mb-4">
                      <a target='_blank' href={news[noticia]?.link} className='text-white'>{news[noticia]?.title}</a>
              </div>
            </div>
          </div>
      </section>
    );
};

export default News;
