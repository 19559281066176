import React from 'react';
import styles from './Feed.module.css';
import FeedModal from './FeedModal2';
import FeedPhotos from './FeedPhotos';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { loadNewPhotos, loadAll, resetFeedState, setCategory } from '../../store/feed';
import Loading from '../Helper/Loading';
import Error from '../Helper/Error';
import Avatar from "avataaars";
import axios from 'axios';
import { API_URL } from '../../Api';

const Feed = ({ user, links, videos, title }) => {

  const [categories, setCategories] = React.useState([]);
  //const [category, setCategory] = React.useState(1);

  /*
  const [modalPhoto, setModalPhoto] = React.useState(null);
  const [pages, setPages] = React.useState([1]);
  const [infinite, setInfinite] = React.useState(true);

  React.useEffect(() => {
    let wait = false;
    function infiniteScroll() {
      if (infinite) {
        const scroll = window.scrollY;
        const height = document.body.offsetHeight - window.innerHeight;
        if (scroll > height * 0.75 && !wait) {
          setPages((pages) => [...pages, pages.length + 1]);
          wait = true;
          setTimeout(() => {
            wait = false;
          }, 500);
        }
      }
    }

    window.addEventListener('wheel', infiniteScroll);
    window.addEventListener('scroll', infiniteScroll);
    return () => {
      window.removeEventListener('wheel', infiniteScroll);
      window.removeEventListener('scroll', infiniteScroll);
    };
  }, [infinite]);
*/
const [modalPhoto, setModalPhoto] = React.useState(null);
const { infinite, loading, list, avatar, userfeed, data, status, error } = useSelector((state) => state.feed);
const dispatch = useDispatch();

React.useEffect(() => {
  getCategories();
}, []);


React.useEffect(() => {
  console.log('mudou');
  dispatch(resetFeedState());
  if (links || videos) {
    dispatch(loadAll({ user, total: 1000, links, videos }));
  } else {
    dispatch(loadNewPhotos({ user, total: 8, links, videos }));
  }
  
}, [dispatch, user, title]);

React.useEffect(() => {
  let wait = false;
  function infiniteScroll() {
    if (infinite) {
      const scroll = window.scrollY;
      const height = document.body.offsetHeight - window.innerHeight;
      if (scroll > height * 0.75 && !wait) {
        dispatch(loadNewPhotos({ user, total: 8, links, videos}));
        wait = true;
        setTimeout(() => {
          wait = false;
        }, 500);
      }
    }
  }

  window.addEventListener('wheel', infiniteScroll);
  window.addEventListener('scroll', infiniteScroll);
  return () => {
    window.removeEventListener('wheel', infiniteScroll);
    window.removeEventListener('scroll', infiniteScroll);
  };
}, [infinite, dispatch, user]);


function getCategories () {
  axios.get(`${API_URL}/getcategories`)
  .then(res => {
      setCategories(res.data);
  })
}

function sendCategory(item) {
  if (item == 1) item = 0; 
  //setCategory(item);
  dispatch(setCategory(item));
  dispatch(resetFeedState());
  dispatch(loadNewPhotos({ user, total: 8, links, videos }));
}

const renderTodos = categories?.map((item, i) => {
  return  (<div key={i} style={{ display: 'grid',
    gridTemplateColumns: '1fr', width: '90%', margin: '0 auto' }}>
    <button onClick={(e) => sendCategory(item.id)} className="btn btn-primary m-1 p-1">{ item.name }</button>
  </div>)
});

return (
  <div className={styles.outside} >
        <div className={styles.perfil}>
        {avatar.length > 0 && <Avatar {...avatar[0]} />}
        {userfeed.length > 0 && <p className='p-3'>{userfeed.username}</p>}
        {renderTodos}       
        </div>

        <FeedModal photo={modalPhoto} setModalPhoto={setModalPhoto} />
    {list.length > 0 && <FeedPhotos />}
    {loading && <Loading />}
    {error && <Error error={error} />}

    {!infinite && !user && (
      <p
        style={{
          textAlign: 'center',
          padding: '2rem 0 4rem 0',
          color: '#888',
        }}
      >
        Não existem mais postagens.
      </p>
    )}
  </div>
);
};

Feed.defaultProps = {
user: 0,
};

Feed.propTypes = {
user: PropTypes.oneOfType([
  PropTypes.string.isRequired,
  PropTypes.number.isRequired,
]),
};

export default Feed;