import React from 'react';
import styles from './select.module.css';

const Select = ({ label, type, name, value, onChange, error, onBlur, invisible, items }) => {
  if (!invisible) {
  return (
    <div className={styles.wrapper} >
      <label htmlFor={name} className={styles.label}>
        {label}
      </label>

      <select id={name} name={name} value={value} className={styles.input} onChange={onChange} onBlur={onBlur}>        
        {items.map((item,i) => (
        <option key={i} value={item.value}>{item.name}</option>
        ))}
      </select>

      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
} else return null
};

export default Select;
