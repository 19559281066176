import React from 'react';
import styles from './PhotoContent.module.css';
import { Link } from 'react-router-dom';
import PhotoComments from './SiteComments';
import PhotoFiles from './PhotoFiles';
import PhotoIdeas from './PhotoIdeas';
import PhotoDelete from './PhotoDelete';
import PhotoUpdate from './SiteUpdate';
import Image from '../Helper/Image';
import swal from 'sweetalert';

import { LIKES_GET_SITE } from '../../Api';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSite } from '../../store/site';
import moment from 'moment';

import Magnifier from "react-magnifier";
import { GlassMagnifier } from "../../magnifier";

const PhotoContentSite = ({ onClose, data, single }) => {
  //const user = React.useContext(UserContext);
  const user = useSelector((state) => state.user);
  const [iframe, setIframe] = React.useState(false);
  const [iframelink, setIframeLink] = React.useState('');
  const [layout, setLayout] = React.useState(false);
  const [iframeNum, setiframeNum] = React.useState(10);
  const [html, setHtml] = React.useState('');
  const [header, setHeader] = React.useState('');
  const [footer, setFooter] = React.useState('');
  const [css, setCss] = React.useState('');
  const [js, setJs] = React.useState('');
  const { photo, comments, files, ideas } = data;
  const dispatch = useDispatch();
  const nodeRef = React.useRef();
  const iframeRef = React.useRef();

  const ver = useSelector((state) => state.site);

  React.useEffect(() => {
    if (photo.page) abrirIframe(photo.page);
  }, []); 

  function abrirIframe(link) {
    setIframe(true);
    setIframeLink(link);
  }

  function updatePage() {
    setIframe(true);
    setIframeLink(photo.page);
    updatePageG();
  }

  function ChangeLayout() {
    setLayout(!layout);
    console.log(layout);
  }

  function updatePageG() {    
    var iframe = document.getElementById('page');
    if (iframe) 
    {
      iframe.src = iframe.src;
      setiframeNum(Math.random());
      //document.getElementById('page').contentDocument.reload(true);
      //iframeRef.src = iframeRef.src;
    }
  }

  async function setLike(e,id) {
    try {
      const { url, options } = LIKES_GET_SITE(id);
      const likes = await fetch(url, options);
      const result = await likes.json();
      console.log(result.likes);
      console.log(e.persist());
      dispatch(fetchSite(id));
    } catch (err) {
      console.log(err);
    } finally {
    }
  }

  function setCopiar (e,id) {
    e.preventDefault()

    var copyText = document.getElementById(id);

    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy");

    swal("Copiado com sucesso!", {
        icon: "success",
        timer: 1000
        });

  }

  function copyText(e, item) {
    e.preventDefault();

    let element = nodeRef.current;
    element.innerHTML = item;

    console.log(element);
    
    var range, selection, worked;

    if (document.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(element);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();        
      range = document.createRange();
      range.selectNodeContents(element);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    
    try {
      document.execCommand('copy');
      swal("Copiado com sucesso!", {
        icon: "success",
        timer: 1000
        });

    }
    catch (err) {
      swal("Não deu!", {
        icon: "error",
        timer: 1000
        });
    }
    
  }

  function setLike2(e,id) {
    try {
      const { url, options } = LIKES_GET_SITE(id);
      const likes = fetch(url, options);
      e.currentTarget.innerHTML = 'Curtido';
      e.currentTarget.disabled = true; 
    } catch (err) {
      console.log(err);
    } finally {
    }
  }

  const styleiframe = {
    width: '100%', 
    height: '100%',
    minHeight: '50vh'
  }

  return (
<div className={`${layout ? 'container-fluid' : 'container'}`}>
<div className="row">
<div className="col-md-12 mb-3">

<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
  <div className="container-fluid">
    <a className="navbar-brand" href="#">Detalhes</a>
    <div className='d-flex gap-3'>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    {onClose && 
              <button id="close" className="btn btn-danger navbar-toggler" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" onClick={(e) => onClose(e)}>X</button>
    }
    </div>
    <div className="collapse navbar-collapse" id="navbarScroll">
      <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" style={{bsScrollHeight: '100px'}}>
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="#">Ideia: {photo.id}</a>
        </li>
        <li className="nav-item">
          {photo.date && (
            <a className="nav-link" href="#">Data: { moment(`${photo.date}`).format('DD/MM/YYYY HH:mm') }</a>
          )}                    
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Conheça também
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
            <li><a className="dropdown-item" href="https://mikagame.com.br/" target="_blank">Mikagame</a></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item" href="https://comendus.com.br/" target="_blank">Comendus</a></li>
          </ul>
        </li>
      </ul>
      <div className="d-flex">

              <div className="btn-group mx-1">
               <button type="button" onClick={(e) => window.open(photo.page,"_blank")} className="btn btn-secondary"><i className="fa fa-link"></i></button>
              </div>

              <div className="btn-group mx-1">
               <button type="button" onClick={(e) => ChangeLayout(e)} className="btn btn-secondary"><i className="fa fa-television"></i></button>
              </div>

              <div className="btn-group mx-1">
               <button type="button" onClick={(e) => updatePageG(e)} className="btn btn-secondary"><i className="fa fa-refresh"></i></button>
              </div>

              <div className="btn-group mx-1">
               <button type="button" onClick={(e) => setLike2(e,photo.id)} className="btn btn-secondary"><i className="fa fa-hand-pointer-o"></i> Curtir</button>
              </div>

              {onClose && 
              <button id="close" onClick={(e) => onClose(e)} className="btn btn-danger d-none d-md-block">X</button>
              }

      </div>
    </div>
  </div>
</nav>



  <div className={`${styles.photo} ${layout ? styles.land : ''} ${single ? styles.single : ''}`}>



      <div className={`${styles.img}`}>

          {iframe ? (
            <iframe id="page" key={iframeNum} src={iframelink} style={styleiframe} allow="camera;microphone" title="Iframe"></iframe>
          )
          :
		      <Magnifier src={photo.src} className={`${styles.mag}`} />
          }

      </div>


      <div className={`${styles.details}`}>


      <div style={{padding : '10px'}}>
          <p className={styles.author}>
            {user.data && user.data.username == photo.author ? (
              <PhotoUpdate id={photo.id} html={html} css={css} js={js} header={header} footer={footer}atualizar={updatePage} />
            ) : (
              <Link to={`/perfil/${photo.author}`}>@{photo.author}</Link>
            )}
            <span className={styles.visualizacoes}>{photo.acessos}</span>
            <span className={styles.likes}>{photo.likes}</span>
          </p>
          {photo.title && (
            <div style={{textAlign : 'left'}}>
              <h1 className="title">
                <Link to={`/idea/${photo.slug}`}>{photo.title}</Link>
              </h1>
              <input type="text" value="outro oijo teste" style={{display : 'none'}} id="h1geral">
              </input>
              <p style={{ opacity : '0', height: '0' }} ref={nodeRef} onClick={() => copyText(this)}></p>

              {photo.link && (
                <div className="btn-group mx-1">
                  <button type="button" onClick={(event) => abrirIframe(photo.link)} className="btn btn-primary"><i className="fa fa-link" aria-hidden="true"></i> Link</button>
                  <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <ul className="dropdown-menu">
                    <li><a id="txtlink" className="dropdown-item" href={photo.link} target="_blank">Abrir link em nova guia</a></li>
                    <li><a className="dropdown-item" onClick={(e) => copyText(e,photo.link)} href={photo.link}>Copiar Link</a></li>
                  </ul>
                </div>
              )}

              {photo.doc && (
                <div className="btn-group mx-1">
                  <button type="button" onClick={(event) => abrirIframe(photo.doc)} className="btn btn-primary"><i className="fa fa-link" aria-hidden="true"></i> Abrir Documento</button>
                  <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <ul className="dropdown-menu">
                    <li><a id="txtdoc" className="dropdown-item" href={photo.doc} target="_blank">Abrir documento em nova guia</a></li>
                    <li><a className="dropdown-item" onClick={(e) => copyText(e,photo.doc)} href={photo.doc}>Copiar Link</a></li>
                  </ul>
                </div>
              )}
              {photo.video && (
                <div className="btn-group mx-1">
                  <button type="button" onClick={(event) => abrirIframe(`https://www.youtube.com/embed/${photo.video}`)} className="btn btn-success"><i className="fa fa-link"></i> Abrir vídeo</button>
                  <button type="button" className="btn btn-success dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <ul className="dropdown-menu">
                    <li><a id="txtvideo" className="dropdown-item" href={`https://www.youtube.com/watch?v=${photo.video}`} target="_blank">Abrir video em nova guia</a></li>
                    <li><a className="dropdown-item" onClick={(e) => copyText(e,`https://www.youtube.com/watch?v=${photo.video}`)} href={photo.video}>Copiar Link</a></li>
                  </ul>
                </div>
              )}
            </div>
          )}

        </div>


      <div className="row">
      <div className="col-12">
      <div className="card card-primary card-outline card-outline-tabs" style={{boxShadow: 'none', margin : 'auto 5px'}}>
      <div className="card-header p-0 pt-1 border-bottom-0">
      <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
      <li className="nav-item">
        <a className="nav-link active" id="custom-tabs-three-home-tab" data-toggle="pill" href="#custom-tabs-three-home" role="tab" aria-controls="custom-tabs-three-home" aria-selected="false">Ideia</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" id="custom-tabs-three-profile-tab" data-toggle="pill" href="#custom-tabs-three-profile" role="tab" aria-controls="custom-tabs-three-profile" aria-selected="false">Comentários</a>
      </li>

      {photo.html &&          
      <li className="nav-item">
        <a className="nav-link" id="custom-tabs-html-profile-tab" data-toggle="pill" href="#custom-tabs-html-profile" role="tab" aria-controls="custom-tabs-html-profile" aria-selected="false">Html</a>
      </li>}

      {photo.header &&          
      <li className="nav-item">
        <a className="nav-link" id="custom-tabs-header-profile-tab" data-toggle="pill" href="#custom-tabs-header-profile" role="tab" aria-controls="custom-tabs-header-profile" aria-selected="false">Header</a>
      </li>}

      {photo.css &&          
      <li className="nav-item">
        <a className="nav-link" id="custom-tabs-css-profile-tab" data-toggle="pill" href="#custom-tabs-css-profile" role="tab" aria-controls="custom-tabs-css-profile" aria-selected="false">Css</a>
      </li>}

      {photo.footer &&          
      <li className="nav-item">
        <a className="nav-link" id="custom-tabs-footer-profile-tab" data-toggle="pill" href="#custom-tabs-footer-profile" role="tab" aria-controls="custom-tabs-footer-profile" aria-selected="false">Footer</a>
      </li>}

      {photo.js &&          
      <li className="nav-item">
        <a className="nav-link" id="custom-tabs-js-profile-tab" data-toggle="pill" href="#custom-tabs-js-profile" role="tab" aria-controls="custom-tabs-js-profile" aria-selected="false">Js</a>
      </li>}

      {files?.length != 0 &&
      <li className="nav-item">
        <a className="nav-link" id="custom-tabs-js-profile-tab" data-toggle="pill" href="#custom-tabs-files-profile" role="tab" aria-controls="custom-tabs-files-profile" aria-selected="false">Arquivos</a>
      </li>}

      {ideas?.length != 0 &&
      <li className="nav-item">
        <a className="nav-link" id="custom-tabs-ideas-profile-tab" data-toggle="pill" href="#custom-tabs-ideas-profile" role="tab" aria-controls="custom-tabs-ideas-profile" aria-selected="false">Blocos</a>
      </li>}

      </ul>
      </div>
      <div className="card-body">
      <div className="tab-content" id="custom-tabs-three-tabContent">
      <div className="tab-pane fade active show" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">

      {photo.body && (

      <div 
        className="ck-content"
        id="editor"
        name="editor" 
        style={{ width: '100%', height: '49vh', overflowY: 'auto', border : 'none', outline : 'none' }}
        dangerouslySetInnerHTML={{ __html: photo.body }}>
      </div>
                         
          )}

      </div>
      <div className="tab-pane fade" id="custom-tabs-three-profile" role="tabpanel" aria-labelledby="custom-tabs-three-profile-tab">

      <PhotoComments single={single} id={photo.id} comments={comments} />

      </div>

      {photo.html &&
      <div className="tab-pane fade" id="custom-tabs-html-profile" role="tabpanel" aria-labelledby="custom-tabs-html-profile-tab">

        <textarea id="html" onChange={(event) => setHtml(event.target.value)} name="html" rows="4" cols="50" style={{width: '100%', outline : '0', border: '0', height : 'calc(100vh - 370px)'}}>
        {photo.html}
        </textarea>

      </div>}

      {photo.header &&
      <div className="tab-pane fade" id="custom-tabs-header-profile" role="tabpanel" aria-labelledby="custom-tabs-header-profile-tab">

        <textarea id="header" onChange={(event) => setHeader(event.target.value)} name="header" rows="4" cols="50" style={{width: '100%', outline : '0', border: '0', height : 'calc(100vh - 370px)'}}>
        {photo.header}
        </textarea>

      </div>}

      {photo.css &&
      <div className="tab-pane fade" id="custom-tabs-css-profile" role="tabpanel" aria-labelledby="custom-tabs-css-profile-tab">

        <textarea id="css" onChange={(event) => setCss(event.target.value)} name="css" rows="4" cols="50" style={{width: '100%', outline : '0', border: '0', height : 'calc(100vh - 370px)'}}>
        {photo.css}
        </textarea>

      </div>}


      {photo.footer &&
      <div className="tab-pane fade" id="custom-tabs-footer-profile" role="tabpanel" aria-labelledby="custom-tabs-footer-profile-tab">

        <textarea id="footer" onChange={(event) => setFooter(event.target.value)} name="footer" rows="4" cols="50" style={{width: '100%', outline : '0', border: '0', height : 'calc(100vh - 370px)'}}>
        {photo.footer}
        </textarea>

      </div>}


      {photo.js &&
      <div className="tab-pane fade" id="custom-tabs-js-profile" role="tabpanel" aria-labelledby="custom-tabs-js-profile-tab">

        <textarea id="js" onChange={(event) => setJs(event.target.value)} name="js" rows="4" cols="50" style={{width: '100%', outline : '0', border: '0', height : 'calc(100vh - 370px)'}}>
        {photo.js}
        </textarea>

      </div>}

      
      {files?.length != 0 &&
      <div className="tab-pane fade" id="custom-tabs-files-profile" role="tabpanel" aria-labelledby="custom-tabs-files-profile-tab">
      <PhotoFiles single={single} id={photo.id} files={files} />
      </div>}


      {ideas?.length != 0 &&
      <div className="tab-pane fade" id="custom-tabs-ideas-profile" role="tabpanel" aria-labelledby="custom-tabs-ideas-profile-tab">
      <PhotoIdeas single={single} id={photo.id} ideas={ideas} />
      </div>}


      </div>
      </div>

      </div>
      </div>
      </div>
      </div>

    </div>

    </div>
    </div>
    </div>
  );
};

export default PhotoContentSite;
