import React from 'react';
import { UserContext } from '../../UserContext';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ children }) => {

  const { data } = useSelector((state) => state.user);

  //async function check() {
    return data ? children : <Navigate to="/login" />;
  //}

  //check();

  //return children;
  //const { login } = React.useContext(UserContext);

  //setTimeout(() => {
  //}, 3000)

};

export default ProtectedRoute;
