import React from 'react';
import styles from './Input.module.css';

const Input = ({ label, type, name, value, onChange, erro = true, error, onBlur, invisible, style }) => {
  if (!invisible) {
  return (
    <div className={styles.wrapper} style={style} >
      <label htmlFor={name} className={styles.label}>
        {label}
      </label>
      <input
        id={name}
        name={name}
        className={styles.input}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {erro && error && <p className={styles.error}>{error}</p>}
    </div>
  );
} else return null
};

export default Input;
