import React from 'react';
import styles from './FeedPhotosItem.module.css';
import Image from '../Helper/Image';

const FeedPhotosItemBig = ({ tipo, photo, setModalPhoto }) => {
  function handleClick() {
    setModalPhoto(photo);
  }

  return (
    <div className={`item ${tipo}`} onClick={handleClick}>
    <div className="content">
      <div className="title">
        <h3>{photo.title}</h3>
      </div>
      <img loading="lazy" className="photothumb scale-up-center" src={photo.src} alt={photo.title}/>
      {photo.body && 
      <div className="desc">
        <p>{photo.body}</p>
      </div>
      }
    </div>
  </div>
  );
};

export default FeedPhotosItemBig;
