import React from 'react';
import styles from './PhotosContent.module.css';
import { SITE_DELETE } from '../../Api';
import useFetch from '../../Hooks/useFetch';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import UserPhotoEdit from '../User/UserSiteEdit';
import { abrir, fechar } from '../../store/modal';
import { Link, useNavigate } from 'react-router-dom';
import SnippetsApp from '../SnippetsApp';
import ImagensApp from '../ImagensAppSite';
import { fetchSite } from '../../store/site';
import { BASE_URL } from '../../config/App';

const PhotosContentItem = (props) => {

  const [title, setTitle] = React.useState(false);
  const [value, setValue] = React.useState('sem valor');
  const [titlebtn, setTitleBtn] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const { request, error } = useFetch();
  const [modalPhoto, setModalPhoto] = React.useState(null);
  //const { loading, data } = useSelector((state) => state.site);
  //const { modal } = useSelector((state) => state);
  const dispatch = useDispatch();

  React.useEffect(() => {
    //dispatch(fetchSite(props.id));     
    //console.log('id do site:',props.id);
    //console.log('----------------------------------------');
    //console.log(props.ideas);
   // dispatch(fetchSite(props.id));
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    //const { url, options } = PHOTO_SET(props.id, { 'description' : value });
    //const { response, json } = await request(url, options);
    //if (response.ok) {
    //  console.log('deu certo');
    //}
  }

  async function handleInfo(event) {
    event.preventDefault();
    //dispatch(abrir());
    setModal(!modal);
  }

  async function AbreEdit(event) {
    //dispatch(abrir());
    setModalPhoto(!modalPhoto);
  }

  async function handleDelete(event) {
    event.preventDefault();
    var target = event.target;

    swal({
      title: "Deseja realmente apagar o registro ?",
      text: "Depois de deletado, você não poderá recuperar esse registro novamente!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then((willDelete) => {
      if (willDelete) {

        async function Deletar() {
          const { url, options } = SITE_DELETE(props.id);
          const { response, json } = await request(url, options);
          if (response.ok) {
            target.innerHTML = json;
            target.disabled = true;
            //target.parentNode.childNodes[1].disabled = true;
            //setTitle(false);
            //setTitleBtn(false);   
          }
        }
        Deletar();
        //return true;
      } else {
          //swal("Your imaginary file is safe!");
      }
      });

  }

  React.useEffect(() => {
    setValue(props.title);
  }, [props.title]);

  function handleItem(event) {
    event.preventDefault();
    setTitle(!title);
    setTitleBtn(!titlebtn);
    if (titlebtn) handleSubmit(event);

  }

  function changeItem(valor) {
    setValue(valor);
  }

  function handleOutsideClick(event) { 
    if (event.target === event.currentTarget) setModalPhoto(null);
   // if (event.target === event.currentTarget) dispatch(closeModal());
  }

  return (
    <>
            <h2 className={styles.title}>{value}</h2>

          <button className="btn btn-success" onClick={(event) => setModalPhoto(!modalPhoto)}><i className="fas fa-edit"></i></button>

          {modalPhoto && <div className={styles.outside} onClick={handleOutsideClick}> 
                <div className={styles.modal}>
                  <UserPhotoEdit id={props.id} fecharItem={setModalPhoto} />
                </div>
              </div>}

              <SnippetsApp UrlSite={BASE_URL} id={props.id}/>
              
              <ImagensApp UrlSite={BASE_URL} TypeFileExt='Audio' TypeFile='2' ProductID={props.id} idPlat={0} />

          <button className="btn btn-danger" onClick={(event) => handleDelete(event)}><i className="fas fa-trash"></i></button>
         

    </>
  );
};

export default PhotosContentItem;
