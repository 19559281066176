import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadNewPhotos } from '../../store/sites';
import SitesContentItem from './SitesContentItem';
import imagesLoaded from 'imagesloaded'; 

const SitesContent = ({ page, user, setModalPhoto, setInfinite }) => {
  const { list, pages, infinite } = useSelector((state) => state.sites);
  var grid, rowHeight, rowGap, rowSpan, allItems, item  = '';

  React.useEffect(() => {
    window.onload = resizeAllGridItems();
    setTimeout(inicia,2000);
  }, []);

  function inicia() {
    try {
      grid = document.getElementsByClassName("grid")[0];
      grid.classList.add("gridAdd");
      resizeAllGridItems();
    }
    catch(err) {
    }
  }

  function tipo() {
    let valor = Math.floor(Math.random() * 4);
    let resultado = '';
    switch(parseInt(valor)) {
      case 0 : resultado = 'photo';
        break;
      case 1 : resultado = 'blog';
        break;
      case 2 : resultado = 'project';
        break;
      default: resultado = 'photo';      
    }
    return resultado;
  }  

  function resizeGridItem(item){
    grid = document.getElementsByClassName("grid")[0];
    rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
    rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
    rowSpan = Math.ceil((item.querySelector('.content').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
      item.style.gridRowEnd = "span "+rowSpan;
  }
  
  function resizeAllGridItems(){
    allItems = document.getElementsByClassName("item");
    //console.log(allItems);
    for(let x=0;x<allItems.length;x++){
      resizeGridItem(allItems[x]);
    }
  }
  
  function resizeInstance(instance){
    item = instance.elements[0];
    resizeGridItem(item);
  }
  
  window.addEventListener("resize", resizeAllGridItems);
  
  allItems = document.getElementsByClassName("item");
  for(let x=0;x<allItems.length;x++){
    imagesLoaded( allItems[x], resizeInstance);
  }	

  function resizeGridItemxX(e){
   // console.log('ver',e);
    //grid = document.getElementsByClassName("grid")[0];
    //rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
    //rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
    //rowSpan = Math.ceil((item.querySelector('.content').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
    //  item.style.gridRowEnd = "span "+rowSpan;
  }



  return (
    <>
      {list?.map((photo,i) => (
        <SitesContentItem
          key={photo.id}
          e={i}
          photo={photo}
          tipo={tipo()}
          setModalPhoto={setModalPhoto}
        />
      ))}
    </>
  );

};

export default SitesContent;
