import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadNewPhotos, loadPhotos, searchPhotos, resetFeedState } from '../../store/feedsites';
import PhotosContent from './PhotosContent';
import PhotosContent2 from './PhotosContent2';
import PhotosLoadMore from './PhotosLoadMore';
import Erro from '../Helper/Error';
import useForm from '../../Hooks/useForm';
import Input from '../Forms/Input';
import Button from '../Forms/Button';
import styles from './Photos.module.css';

const Photos = (props) => {
  //const data =  useSelector((state) => state.photos);
  const dispatch = useDispatch();
  const title = useForm();
  let lista = [];
  const [listax, setListax] = React.useState('');
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    console.log(props);
    dispatch(loadPhotos({ page: 1, total: 8, user:props.user }));
    //dispatch(loadNewPhotosInicialInt(1,props.user));
    //const data = state.photos;
    //dispatch(fetchPhotos2());
    //console.log('data');
    //console.log(data);
  }, [dispatch]);

  function setPage(page) {

    var value = page;
    var url = ''; 
    if (validURL(value)) {
      url = new URL(value);
      if (url.searchParams.has("page")) {
      value = url.searchParams.get("page");
      }
    }

    console.log(value);
    dispatch(loadPhotos({page: value, total: 8, user:props.user, key:title.value }));
  }

  function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  function handleSubmit(event) {
    event.preventDefault();
    try {
      if (title.value.length) 
        dispatch(searchPhotos({ total: 8, user:props.user, key:title.value }));
        else {
          dispatch(resetFeedState());
          dispatch(loadPhotos({ page: 1,  total: 8, user:props.user }));
        }
      
    } 
    catch(err) {

    }
    }

  return (
    <section>
      <form className={`${styles.formulariosearch} anime`} onSubmit={handleSubmit}>
        <Input label="Pesquisar" type="text" name="title" erro={false} {...title} />
        {loading ? (
          <Button disabled>Pesquisando...</Button>
        ) : (
          <Button>Pesquisar</Button>
        )}
        <Erro error={error} />
      </form>
      <PhotosContent setPage={setPage} user={props.user} />
    </section>
  );
};

export default Photos;
