import React from 'react';
import styles from './Footer.module.css';
import { ReactComponent as Dogs } from '../Assets/logo.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
    <footer id="footer" className={styles.footer}>
      <Dogs style={{ maxWidth: '250px', maxHeight: '250px'}}/>
      <p>MyBrain Idea - onde as ideias se completam.</p>
      <div style={{display: 'flex', justifyContent: 'center', gap: '10px'}}>
      <Link className={styles.login} to="/delphi">
          Delphi
      </Link>
      <Link className={styles.login} to="/snippets">
          Snippets
      </Link>
      <Link className={styles.login} to="/sites">
          Sites
      </Link>
      </div>
    </footer>
    <div className={styles.banner}>
      <a href="https://brazilcats.com.br" target='_blank'>Desenvolvido por Brazilcats - Sites e Sistemas</a>
    </div>
    </>
  );
};

export default Footer;
