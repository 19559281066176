import React from 'react';
import styles from './PhotosContent.module.css';
import { PHOTO_SET, PHOTO_DELETE } from '../../Api';
import useFetch from '../../Hooks/useFetch';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import UserPhotoEdit from '../User/UserPhotoEdit';
import { abrir, fechar } from '../../store/modal';
import { Link, useNavigate } from 'react-router-dom';
import ImagensApp from '../ImagensApp';
import { loadSnippets } from '../../store/photos';
import axios from 'axios';
import { BASE_URL, API_URL } from '../../config/App';

const PhotosContentItem = (props) => {

  const [title, setTitle] = React.useState(false);
  const [value, setValue] = React.useState('sem valor');
  const [titlebtn, setTitleBtn] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const { request, error } = useFetch();
  const [modalPhoto, setModalPhoto] = React.useState(null);
  //const { modal } = useSelector((state) => state);
  const dispatch = useDispatch();

  async function handleSubmit(event) {
    event.preventDefault();
    const { url, options } = PHOTO_SET(props.id, { 'description' : value });
    const { response, json } = await request(url, options);
    if (response.ok) {
      console.log('deu certo');
    }
  }

  
  async function handleInfo(event) {
    event.preventDefault();
    //dispatch(abrir());
    setModal(!modal);
  }

  async function AbreEdit(event) {
    //dispatch(abrir());
    setModalPhoto(!modalPhoto);
  }

  async function handleDelete(event) {
    event.preventDefault();
    var target = event.target;

    swal({
      title: "Deseja realmente apagar o registro ?",
      text: "Depois de deletado, você não poderá recuperar esse registro novamente!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then((willDelete) => {
      if (willDelete) {

        async function Deletar() {
          const { url, options } = PHOTO_DELETE(props.id);
          const { response, json } = await request(url, options);
          if (response.ok) {
            target.innerHTML = json;
            target.disabled = true;
            //target.parentNode.childNodes[2].disabled = true;
            setTitle(false);
            setTitleBtn(false);   
          }
        }
        Deletar();
        //return true;
      } else {
          //swal("Your imaginary file is safe!");
      }
      });

  }


  async function handleDuplicate(event) {
    event.preventDefault();
    var target = event.target;

    swal({
      title: "Deseja realmente duplicar o registro ?",
      text: "Depois de duplicado, você poderá apagar esse registro!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then((willDelete) => {
      if (willDelete) {

        axios ({
          method: 'POST',
          url: `${API_URL}/club/photo/duplicate/${props.id}`,
          data: {'id':props.id}
      }).then(function (response) {
          console.log(response);
          //dispatch(loadSnippets());
      }).catch(function (error) {
          console.log('Error: ', error);
      });

        //return true;
      } else {
          //swal("Your imaginary file is safe!");
      }
      });

  }

  React.useEffect(() => {
    setValue(props.title);
  }, [props.title]);

  function handleItem(event) {
    event.preventDefault();
    setTitle(!title);
    setTitleBtn(!titlebtn);
    if (titlebtn) handleSubmit(event);

  }

  function changeItem(valor) {
    setValue(valor);
  }

  function handleOutsideClick(event) {
    console.log('target');
    console.log(event.target);

    console.log('onclose');
    console.log(event.currentTarget);    
    if (event.target === event.currentTarget) setModalPhoto(null);
   // if (event.target === event.currentTarget) dispatch(closeModal());
  }

  return (
    <>

          <h2 className={styles.title}>{value}</h2>

          <button className="btn btn-info" onClick={(event) => handleDuplicate(event)}><i className="fas fa-id-card"></i></button>

          <button className="btn btn-success" onClick={(event) => setModalPhoto(!modalPhoto)}><i className="fas fa-edit"></i></button>

          {modalPhoto && <div className={styles.outside} onClick={handleOutsideClick}> 
                <div className={styles.modal}>
                  <UserPhotoEdit id={props.id} fecharItem={setModalPhoto} />
                </div>
              </div>}

              <ImagensApp UrlSite={BASE_URL} TypeFileExt='Audio' TypeFile='2' ProductID={props.id} idPlat={0} />
              
          <button className="btn btn-danger" onClick={(event) => handleDelete(event)}><i className="fas fa-trash"></i></button>
         
          <div style={{ width : '24px', height : 'auto', cursor: 'pointer', display: 'none'}} onClick={(event) => handleInfo(event)}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
          </svg>
          </div>

          {modal && <div className={styles.modalitem}>
            <div onClick={(event) => handleInfo(event)} className={styles.fechar}>X</div>
            <img src={props.src} alt={props.title} className={styles.img} />
            <div style={{ padding: '10px'}}>{props.title}</div>
          </div>}

    </>
  );
};

export default PhotosContentItem;
