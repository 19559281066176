import createAsyncSlice from './helper/createAsyncSlice';
import { API_URL } from '../config/App';

let sites = createAsyncSlice({
  name: 'sites',
  initialState: {
    list: [],
    user: 0,
    pages: 1,
    infinite: true,
  },
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    clearPhotos(state, action) {
      state.list = [];
    },
    addPage(state) {
      state.pages++;
    },    
    addPhotos(state, action) {
      state.list.push(...action.payload.data);
      //state.pages++;
      if (action.payload.meta.current_page === action.payload.meta.last_page) state.infinite = false;
    },
    removePhotos(state) {
      state.pages = 1;
      state.infinite = true;
      state.list = [];
      state.data = null;
      state.error = null;
      state.loading = false;
    },
    searchbyTitle(state, action) {

      state.list.push(...action.payload.payload.data);

      //const data = action.payload.payload.data;
      //const overFiveKg = data?.filter(({ title }) => title.toUpperCase().indexOf(action.payload.chave.toUpperCase()) > -1);
      //const transformPound = overFiveKg?.map((photo) => ({
      //  ...photo,
      //  peso: Math.floor(photo.peso * 5.2),
      //}));

      //state.list.push(...transformPound);
      state.infinite = false;
    },
  },
  fetchConfig: (data) => ({
    url: `${API_URL}/club/getallsites?page=${data.page}&total=20&key=${data.key}&user=${data.user}&shared=${data.shared}&visible=${data.visible}`,
    options: {
      method: 'GET',
      cache: 'no-store',
    },
  }),
});

const slice = createAsyncSlice({
  name: 'sites',
  initialState: {
    cache: 5000,
    list: [],
    user: 0,
    pages: 0,
    infinite: true,
  },
  fetchConfig: () => ({
    url:
    `${API_URL}/club/getallsites?page=1&total=6&user=0`,
    options: {
      method: 'GET',
      cache: 'no-store',
    },
  }),
});


export const {  addPhotos,  addPage, addPhotos2, searchbyTitle, setUser, clearPhotos, removePhotos } = sites.actions;
export const fetchPhotos = sites.asyncAction;
export const fetchPhotos2 = slice.asyncAction;

export const getOverFiveKg = (state) => {
  const data = state;
  const overFiveKg = data?.filter(({ peso }) => peso >= 0);
  const transformPound = overFiveKg?.map((photo) => ({
    ...photo,
    peso: Math.floor(photo.peso * 2.2),
  }));
  return transformPound;
};

export const getbyTitle = (state, chave) => {
  console.log(chave);
  const data = state;

  const overFiveKg = data?.filter(({ title }) => title.indexOf(chave) > -1);
  const transformPound = overFiveKg?.map((photo) => ({
    ...photo,
    peso: Math.floor(photo.peso * 5.2),
  }));
  return transformPound;
};

export const loadNewPhotose = (page = 1, user = 0, key = '', shared = 1, visible = 1) => async (dispatch) => {
  const { payload } = await dispatch(fetchPhotos({page, user, key, visible}));
  dispatch(addPhotos(payload));
};

export const loadNewPhotos = (area = 1, page = 1, user = 0, key = '', shared = 1, visible = 1) => async (
  dispatch,
  getState,
) => {
  console.log (area);
  const { sites } = getState();
  dispatch(addPage());
  const { payload } = await dispatch(
    fetchPhotos({ page: sites.pages, user, key, shared, visible, area }),
  );
  dispatch(addPhotos(payload));
};

export const loadNewPhotosInt = (page = 1, user = 1, key = '', shared = 1, visible = 1) => async (dispatch) => {
  console.log('user',user);
  const { payload } = await dispatch(fetchPhotos({page:page, user:user, key:key, shared:shared, visible:visible}));
  dispatch(addPhotos(payload));
};

export const searchTitle = (chave = '',user = 0, shared = 1,visible = 1) => async (dispatch) => {
  const { payload } = await dispatch(fetchPhotos({user:user, key:chave, shared:shared, visible:visible}));
  dispatch(removePhotos());
  dispatch(searchbyTitle({payload, chave}));
};

export const loadNewPhotosInicial2 = (page = 1, user = 0, key = '', shared = 1, visible = 1) => async (dispatch) => {
  const { payload } = await dispatch(fetchPhotos({page, user, key, shared, visible}));
  dispatch(removePhotos());
  dispatch(addPhotos(payload));
};

export const loadNewPhotosInicial = (page = 1, user = 0, key = '', shared = 1, visible = 1) => async (
  dispatch,
  getState,
) => {
  const { sites } = getState();
  dispatch(addPage());
  const { payload } = await dispatch(
    fetchPhotos({ page: sites.pages, user, key, shared, visible }),
  );
  dispatch(removePhotos());
  dispatch(addPhotos(payload));
};

export const loadNewPhotosInicialInt = (page = 1, user = 234, key = '', shared = 1, visible = 1) => async (dispatch) => {
  console.log('user',user);
  const { payload } = await dispatch(fetchPhotos({page:page, user:user, key:key, shared:shared, visible:visible}));
  dispatch(removePhotos());
  dispatch(addPhotos(payload));
};

export default sites.reducer;
