import React from 'react';
import Mymodal from './Mymodal';
import MymodalEdit from './MymodalEdit';
//import Mega from "./Mega";

export default class IdeaItem extends React.Component{

    constructor(props) {
        super(props);
        this.state = {deps:[], Mymodal: false}
    }

    mensagem = (texto) => {
        console.log(texto);
    }

    ampliarImagem = (event) => {
        console.log(event.target.src);
    }


    render(){

        const addModal2Close = () => {
            this.setState({addModal2Show: false});            
            this.props.ordenar();

            var self = this;
            setTimeout(function() {
                self.props.atualizar();    
            }, 1500);
    
        }

        let addModalClose =() => this.setState({addModalShow: false}) 

        return(
            <div>
                <div className="btn-group p-1" style={{ position: 'absolute', top: '10px', right: '10px'}} role="group" aria-label="Comandos">
                <button className='btn btn-primary' onClick = {()=> this.setState({addModal2Show:true})} ><i className="fa fa-fw fa-edit"></i></button>
                <button className='btn btn-danger' onClick = {()=> this.props.deletar(this.props.id)}><i className="fa fa-fw fa-trash"></i></button>
                </div>
                <span style={{ position: 'absolute', top: '40px', left: '25px', color: '#fff'}}>{this.props.image.split('.').pop()}</span>
                <img onClick = {()=> this.setState({addModalShow:true})} data-toggle="modal" className="img-fluid" src={`${this.props.UrlSite}storage/file.svg`} style={{ width: '60px', height: 'auto', cursor: 'pointer'}} alt={this.props.alt} title={this.props.title}/>
                <span>{ this.props.id } - {this.props.description}</span>
                <input style={{ display : 'none'}} type="text" readOnly className="form-control" id={"txt" + this.props.id} value={`${this.props.UrlSite}storage/${this.props.image}`} />
                <Mymodal show={this.state.addModalShow} imagem = {`${this.props.UrlSite}storage/${this.props.image}`} link = 'link' titulo = {`Arquivo: ${this.props.id}`} text = 'Arquivo' onHide = {addModalClose}/>    
                <MymodalEdit show={this.state.addModal2Show} imagem = {`${this.props.UrlSite}storage/${this.props.image}`} link = 'link' titulo = {`Arquivo: ${this.props.id}`} text = 'Arquivo' item={this.props.item} onHide = {addModal2Close}/>    
            </div>

        );
    }
    }
