import React from 'react';
import styles from './PhotoUpdate.module.css';
import { SITE_UPDATE } from '../../Api';
import useFetch from '../../Hooks/useFetch';

const SiteUpdate = ({ id, header, footer, html, css, js, atualizar }) => {
  const { loading, request } = useFetch();

  async function handleClick() {

      const formData = new FormData();
    
      console.log ('html',html);

      if (html) formData.append('html', html);
      if (css) formData.append('css', css);
      if (js) formData.append('js', js);
      if (header) formData.append('header', header);
      if (footer) formData.append('footer', footer);

      const { url, options } = SITE_UPDATE(formData, id);
      const { response, json } = await request(url, options);
      console.log (json);
      if (response.ok) atualizar();
  }

  return (
    <>
      {loading ? (
        <button className={styles.update} disabled>
          Atualizar
        </button>
      ) : (
        <button onClick={handleClick} className={styles.update}>
          Atualizar
        </button>
      )}
    </>
  );
};

export default SiteUpdate;
