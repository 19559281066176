import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadNewPhotos } from '../../store/photos';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useFetch from '../../Hooks/useFetch';
import { SUBGRUPOS_GET } from '../../Api';

const Modulos = (props) => {
  const {request, data } = useFetch();
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    async function getData() {

      const { url, options } = SUBGRUPOS_GET(props.id);
      const { json } = await request(url, options);

    }
    getData();
  }, [request,props]);

  return (
    <div className="tab-content" id="myTabContent" style={{display: 'flex',
      flexWrap: 'wrap'}}>
      {data?.map((item,i) => (
      <div key={i} className="gareai">
      <Link to={{pathname: `/courses/lessons`, search: `?area=${props.area}&group=${props.id}&subgroup=${item.id}`}} className="aquadi"><div className="gquadi"><span className="gtexti">{item.order}</span></div></Link>
         <div>{item.title}</div>
         </div>
      ))}
    </div>
);

};

export default Modulos;