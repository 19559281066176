import React from 'react';
import {Modal} from 'react-bootstrap';
import useForm from './Hooks/useForm';
import useFetch from './Hooks/useFetch';
import Input from './Forms/Input';
import Select from './Forms/Select2';
import SelectVisible from './Forms/SelectVisible';
import Button from './Forms/Button';
import Erro from './Helper/Error';
import { PHOTO_SET2 } from '../Api';

const MymodalEdit = (props) => {
  const titulo = useForm();
  const visibilidade = useForm();
  const tipo = useForm();
  var created_at = new Date(props.item.created_at).toLocaleDateString("pt-BR")
  const [error, setError] = React.useState(null);
  const { data, loading, request, response } = useFetch();

  React.useEffect(() => {
    if (props.item.description) titulo.setValue(props.item.description);
    visibilidade.setValue(props.item.visibility);
    tipo.setValue(props.item.type);
    //console.log (props.item);
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    const { url, options } = PHOTO_SET2(props.item.id, { 'titulo' : titulo.value, 'visibilidade' : visibilidade.value, 'tipo' : tipo.value });
    const { response, json } = await request(url, options);
    if (response.ok) {
      props.onHide();
      console.log('deu certo', json);
      
      //props.item.setDescription = titulo.value;
    }
  }
    
    function checaextensao () {

      var arq = props.imagem;
      var ext = arq.split('.').pop();

      if (ext === 'gif' || ext === 'jpeg' || ext === 'jpg' || ext === 'png') {
        return (
          <img className="img-fluid rounded" src={process.env.PUBLIC_URL + arq} alt=""/>
        )} else if (ext === 'pdf') {
          return (
            <iframe src={process.env.PUBLIC_URL + arq} style={{ width: '100%', height: '50vh', border : 'none'}} title={ext}></iframe>
          )} else if (ext === 'mp3' || ext === 'wav' || ext === 'm4a') {
            return (
              <audio controls>
              <source src={process.env.PUBLIC_URL + arq} type="audio/mpeg"/>
              </audio>
          )} else {
            return (
              <a href={process.env.PUBLIC_URL + arq} target="_blank" rel="noreferrer">Clique para abrir</a>  
            )
          }

    }


    return (
 
      <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        <h2 className="portfolio-modal-title text-secondary mb-0"># {props.item.id} - { created_at}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body2">
      <div className="row justify-content-center">
              <div className="col-lg-12 text-center">
                <form onSubmit={handleSubmit}>
                  <Input label="Titulo" type="text" name="titulo" {...titulo} />
                  <Select label="Tipo" type="text" name="tipo" {...tipo} />
                  <SelectVisible label="Visibilidade" type="text" name="visibilidade" {...visibilidade} />
                  {loading ? (
                    <Button disabled>Atualizando...</Button>
                  ) : (
                    <Button>Atualizar</Button>
                  )}
                  <Erro error={error} />
                </form>
              </div>
            </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer2">
      </Modal.Footer>
    </Modal>
 
      );
  }

export default MymodalEdit;
