import React from 'react';
import styles from './pesquisar.module.css';
import { useDispatch, useSelector } from 'react-redux';
import useForm from '../../Hooks/useForm';
import Search from './search';
import { searchTitle, loadNewPhotos, removePhotos} from '../../store/photos';

const Pesquisar = () => {
  const title = useForm();
  const dispatch = useDispatch();
  var grid, rowHeight, rowGap, rowSpan, allItems, item  = '';

  function handleSubmit(event) {
    event.preventDefault();
    try {
      window.scrollTo(0, 0);
      setTimeout(() => {
        if (title.value.length) 
        dispatch(searchTitle(title.value));
        else {
        dispatch(removePhotos());
        dispatch(loadNewPhotos());
        window.onload = resizeAllGridItems();
        setTimeout(inicia,2000);}
      }, 1000)
    } 
    catch(err) {

    }
    }  
  
    function inicia() {
      grid = document.getElementsByClassName("grid")[0];
      if (grid) {
      grid.classList.add("gridAdd");
      resizeAllGridItems();
      }
    }

    function resizeGridItem(item){
      grid = document.getElementsByClassName("grid")[0];
      rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
      rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
      rowSpan = Math.ceil((item.querySelector('.content').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
        item.style.gridRowEnd = "span "+rowSpan;
    }
    
    function resizeAllGridItems(){
      allItems = document.getElementsByClassName("item");
      //console.log(allItems);
      for(let x=0;x<allItems.length;x++){
        resizeGridItem(allItems[x]);
      }
    }

  return (
    <form onSubmit={handleSubmit} className={`${styles.wrapper}`}>
    <Search label="Pesquisar" Procurar={handleSubmit} name="search" type="text" {...title}/>
    </form> 
  );
} 

export default Pesquisar;
