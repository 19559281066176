import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Inicial from './Components/Inicial';
import Sites from './Components/Sites';
import Area from './Components/Area';
import Login from './Components/Login/Login';
import { UserStorage } from './UserContext';
import User from './Components/User/User';
import Main from './Components/Main/Main';
import CoursesItem from './Components/Courses/CoursesItem';
import Playlist from './Components/Courses/Playlistout';
import ProtectedRoute from './Components/Helper/ProtectedRoute';
import Photo from './Components/Photo/Photo';
import Date from './Components/Date/Date';
import UserProfile from './Components/User/UserProfile';
import NotFound from './Components/NotFound';
import { useDispatch } from 'react-redux';
import { autoLogin } from './store/user';
import ScrollToTop from "./Components/Util/Gotop";

function App() {
  
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(autoLogin());
  }, [dispatch]);

  return (
    <div id="App" className="App">
      <BrowserRouter>
        <UserStorage>
          <Header />
          <main className="AppBody">
            <Routes>

              <Route path="/" element={<Inicial area_id="1"/>} />

              <Route path="login/*" element={<Login />} />

              <Route path="delphi/*" element={<Area area_id="2"/>} />
              <Route path="snippets/*" element={<Area area_id="3"/>} />
              <Route path="sites/*" element={<Sites/>} />

              <Route
                path="count/*"
                element={
                  <ProtectedRoute>
                    <User />
                  </ProtectedRoute>
                }
              />


              <Route
                path="courses/*"
                element={
                  <ProtectedRoute>
                    <CoursesItem/>
                  </ProtectedRoute>
                }
              />
              
              <Route path="datas/" element={<Date />} />
             
              <Route path="idea/:id" element={<Photo />} />
              <Route path="perfil/:user" element={<UserProfile />} />
              <Route path="perfil/playlist/:user" element={<Playlist />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
          <ScrollToTop/>
          <Footer />
        </UserStorage>
      </BrowserRouter>
    </div>
  );
}

export default App;
