import React from 'react';
import { loadNewPhotos } from '../../store/photos';
import axios from 'axios';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useFetch from '../../Hooks/useFetch';
import { UserContext } from '../../UserContext';
import { LESSONS_GET, FILE_GET} from '../../Api';
import fileDownload from 'js-file-download';
import swal from 'sweetalert';
import { BASE_URL, API_URL } from '../../config/App';

const Lessons = (props) => {
  const {request, data } = useFetch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [area, setAreax] = React.useState(searchParams.get("area"));
  const [areadesc, setAreaDesc] = React.useState(searchParams.get("area"));
  const [downloads, setDownloads] = React.useState([]);
  const [group, setGroup] = React.useState(searchParams.get("group"));
  const [subgroup, setSubGroup] = React.useState(searchParams.get("subgroup"));
  const [audios, setAudios] = React.useState([]);
  const [audios2, setAudios2] = React.useState([]);
  const [playlist, setPlaylist] = React.useState([]);
  const [licao, setLicao] = React.useState({});
  const [video, setVideo] = React.useState(null);
  const [title, setTitle] = React.useState(null);
  const [video_local, setVideoLocal] = React.useState(null);
  const [finalizado, setFinalizar] = React.useState(false);
  const [urlimg, seturlimg] = React.useState(`${BASE_URL}/storage/`);
  const { setArea } = React.useContext(UserContext);
  const state = useSelector((state) => state.inc);
  const user = useSelector((state) => state.user);
  const player = React.useRef(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    async function getData() {

      let group = searchParams.get("group");
      let subgroup = searchParams.get("subgroup");

      const { url, options } = LESSONS_GET(group,subgroup);
      const { response, json } = await request(url, options);

      if (json) {
        setLesson(json[0])
      }

    }
    getData();
  }, [request,props]);

  React.useEffect(() => {
  }, []);

  const getList = (user, language) => {

    setPlaylist ([]);

    var _self = this;
    axios ({
        method: 'POST',
        url: `${API_URL}/lessons/getplaylist`,
        data: {'user_id':user,'language_id':language}
    }).then(function (response) {

        response.data.forEach(function (item) {
          setPlaylist (playlist => [...playlist, item['lesson_photos']]);
          //playlist.push (item['lesson_photos']);
        });

    }).catch(function (error) {
        console.log('Error: ', error);
    });
}


  function getFile (id,image) {
    var _self = this;
    axios ({
        method: 'GET',
        url: `${API_URL}/club/files/${id}`
    }).then(function (response) {

        handleDownload(`${API_URL}/club/files/${id}`, image);

    }).catch(function (error) {
        console.log('Error: ', error);
    });
}

function getMyLessons (user,licao) {

  var _self = this;
  axios ({
      method: 'POST',
      url: `${API_URL}/club/getmylessons`,
      data: {'user_id':user,'lesson_id':licao}
  }).then(function (response) {

      if (response.data.length) {
        setFinalizar(true);
      } else {
        setFinalizar(false);
      }

      console.log ('response.data');
      console.log (response.data);

  }).catch(function (error) {
      console.log('Error: ', error);
  });
  
}

  function setStatus() {
    finalizado ? setunFinished() : setFinished();
  }

  function setFinished () {

    var _self = this;
    axios ({
        method: 'POST',
        url: `${API_URL}/club/setfinished`,
        data: {'user_id':user.data.id,'lesson_id':licao.id,'area_id':licao.area_id}
    }).then(function (response) {

      getMyLessons(user.data.id, licao.id);

    }).catch(function (error) {
        console.log('Error: ', error);
    });
    
  }

  function addPlaylist(itemid, itemimagem)
  {
    var datatal = {
      user_id: user.data.id,
      language_id: licao.area_id, 
      lesson_id: licao.id,
      lesson_photos: itemid 
    }

    fetch(`${API_URL}/lessons/addplaylist`, {
      method: "POST",
      body: JSON.stringify(datatal),
      headers: {"Content-type": "application/json; charset=UTF-8"}
    })
    .then(response => response.json()) 
    .then(json => {

      console.log(json);

      getList(user.data.id, licao.area_id);

      swal(`Esse arquivo foi adicionado a sua playlist: ${json.lesson_photos}`, {
        icon: "success",
        timer: 1000
        });
        

    })
    .catch(err => console.log(err));
  }


  function loadAudio(itemid, itemimagem)
  {
    let audio = itemimagem.querySelector("audio");
    audio.src = itemid;
    audio.load();
  }


  function removePlaylist(itemid, itemimagem)
  {
    var datatal = {
      user_id: user.data.id,
      language_id: licao.area_id, 
      lesson_id: licao.id,
      lesson_photos: itemid 
    }

    fetch(`${API_URL}/lessons/removeplaylist`, {
      method: "POST",
      body: JSON.stringify(datatal),
      headers: {"Content-type": "application/json; charset=UTF-8"}
    })
    .then(response => response.json()) 
    .then(json => {

      getList(user.data.id, licao.area_id);

      swal(`Esse arquivo foi removido da sua playlist: ${json.lesson_photos}`, {
        icon: "success",
        timer: 1000
        });
        
    })
    .catch(err => console.log(err));
  }


  function setunFinished () {

    var _self = this;
    axios ({
        method: 'POST',
        url: `${API_URL}/club/unsetfinished`,
        data: {'user_id':user.data.id,'lesson_id':licao.id,'area_id':licao.area_id}
    }).then(function (response) {

      getMyLessons(user.data.id, licao.id);

    }).catch(function (error) {
        console.log('Error: ', error);
    });
    
  }


  function setLesson(item) {
    setAudios([]);
    setLicao(item);
    setVideo(item.video);
    setTitle(item.name);
    setVideoLocal(item.video_local);
    setAreaDesc(item.areadesc);
    setArea(item.areadesc);
    setGroup(item.groupdesc);
    setSubGroup(item.subgroupdesc);
    getMyLessons(user.data.id, item.id);

    let downloads = item.photos.filter(function (el,i) {
        return el.type == 1;
      });
    setDownloads(downloads);

    let audios = item.photos.filter(function (el,i) {
        return el.type == 2;
      });
    setAudios(audios);

      getList(user.data.id, licao.area_id);
      console.log (playlist);    
      
    //console.log(item);
  }

  function handleDownload (url, filename) {
    axios.get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename)
    })
  }

  return (

    <div className="section">
        <div className="container" style={{minWidth: '100%'}}>
            <div className="row">
                <div className="col-md-12 p-0 m-0">

                        <ul className="nav nav-pills formatoitem" style={{borderRadius : '0'}} id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <Link to={{pathname: `/courses/area`, search: `?id=${area}`}} className="btn btn-danger my-1 mx-2" aria-selected="true">Voltar</Link>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="g01" data-bs-toggle="tab" data-bs-target="#gd01" type="button" role="tab" aria-controls="g01" aria-selected="true">Vídeo</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="g02" data-bs-toggle="tab" data-bs-target="#gd02" type="button" role="tab" aria-controls="g01" aria-selected="true">Downloads</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="g03" data-bs-toggle="tab" data-bs-target="#gd03" type="button" role="tab" aria-controls="g03" aria-selected="true">Audios</button>
                            </li>
                        </ul>

                        <div className="garealesson" style={{display: 'flex', marginTop: '0', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', padding: '10px', position: 'relative'}}>
                            <div>
                            <h3 className="text-left">{title}</h3>
                            <p className="m-0">{`Etapa: ${group} - Modulo: ${subgroup}`}</p> 
                            </div>
                            <button onClick={() => setStatus()} data-lesson style={{position: 'absolute', right: '10px', top: '10px'}} aria-language="{{$user->area_id}}" aria-user="{{$user->id}}" aria-lesson="{{$lesson->id}}" className="btn btn-secondary btn-sm">
                              {finalizado ? 'Finalizado' : 'Finalizar'}
                            </button>
                        </div>

                        <div className="garea">    

                        <div className="row">
                        <div className="col-12 col-md-8">

                          <div className="tab-content" id="myTabContent">

                                <div className="tab-pane fade show active" id="gd01" role="tabpanel" aria-labelledby="g01-tab">
                                        <div className="garea">
                                        
                                        {video && 
                                        <iframe style={{maxWidth:'100%', maxHeight:'100%'}} width="100%" height="340px" src={video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                        }
                                        
                                        {video_local && 
                                        <video src={video_local} width="100%" height="100%" controls>
                                        Your browser does not support the video tag.
                                        </video>
                                        }

                                        </div>
                                </div>

                                <div className="tab-pane fade" id="gd02" role="tabpanel" aria-labelledby="g02-tab">
                                    <div className="garea">
                                    

                                    <ul className="lessons ml-0" id="downloads">
                                        {downloads?.map((item,i) => (
                                        <li key={i} className="lessons-item">
                                        <button className="btn btn-dark" type="button" onClick={() => getFile(item.id,item.image)}>{item.description}</button>
                                        </li>
                                        ))}
                                    </ul>

                                    </div>
                                </div>

                                <div className="tab-pane fade" id="gd03" role="tabpanel" aria-labelledby="g03-tab">
                                    <div className="garea">
                                    

                                    <ul className="lessons ml-0" id="audios">
                                        {audios?.map((item,i) => (
                                        <li key={i} className="lessons-item">
                                        <audio onClick={(event) => loadAudio(item.id,event.currentTarget)} className="nav-item p-1" controls>
                                        </audio>
                                        <p className="text-white p-1">{item.description}</p>
                                        <button className="btn btn-dark" type="button" onClick={(event) => loadAudio(item.imagem,event.target.parentElement)}>Load</button>

                                        {!playlist?.includes(item.id) 
                                        ?<button className="btn btn-dark" type="button" onClick={() => addPlaylist(item.id,item.image)}>[+] Adicionar à sua playlist</button>
                                        :<button className="btn btn-danger" type="button" onClick={() => removePlaylist(item.id,item.image)}>[-] Remover da playlist</button>
                                        }
                                        </li>
                                            ))}
                                    </ul>

                                    </div>
                                </div>


                            </div>
                        
                        </div>
                        <div className="col-12 col-md-4">


                            <ul className="lessons" id="lessons">
                                <li className="lessons-item">
                                {data?.map((item,i) => (
                                    <a key={i} onClick={() => setLesson(item)} data-tooltip aria-label={item.name} className="nav-item my-1" type="button">{item.name}</a>
                                    ))}
                                </li>
                            </ul>

                        </div>    
                        </div>    
    

                    </div>

                </div>
            </div>
        </div>
    </div>
);

};

export default Lessons;