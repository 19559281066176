import axios from "axios"
import { useState } from "react"
import React from 'react'
import { Container, Row, Col, Form, Button, ProgressBar, Alert } from "react-bootstrap"
import ReactResumableJs from 'react-resumable-js';
import { API_URL } from "../config/App";

//import axiosInstance from "./Axios"

const UploadAppGSite = (props) => {

  return (

<ReactResumableJs
            uploaderID="image-upload"
            dropTargetID="myDropTarget"
            filetypes={["jpg", "JPG", "png", "PNG", "m4a", "mp3", "mp4", "MP4", "mkv"]}
            maxFileSize={512000000}
            fileAccept="*/*"
            fileAddedMessage="Started!"
            completedMessage="Complete!"
            service={`${API_URL}/filessite/uploadG/${props.ProductID}`}
            textLabel=""
            previousText=""
            disableDragAndDrop={true}
            onFileSuccess={(file, message) => {
              //this.props.setFiles(file, message);
              //this.inputDisable = false;
              console.log(file,message);
              props.Atualizar();
              props.Last();
              props.Titulo('Banco de Arquivos');
            }}
            onFileAdded={(file, resumable) => {
              console.log (file);
              resumable.upload();
            }}
            maxFiles={1}
            startButton={false}
            pauseButton={false}
            cancelButton={false}
            onStartUpload={() => {
                console.log("Start upload");
            }}
            onfileProgress={(file) => {
              console.log(Math.floor(file.progress() * 100));
            }}
            onCancelUpload={() => {
                this.inputDisable = false;
            }}
            onPauseUpload={() =>{
                this.inputDisable = false;
            }}
            onResumeUpload={() => {
                this.inputDisable = true;
            }}
          />
  )
}

export default UploadAppGSite