import React from 'react';
import styles from './FeedModalEdit.module.css';
import useFetch from '../../Hooks/useFetch';
import Error from '../Helper/Error';
import Loading from '../Helper/Loading';
import { PHOTO_GET } from '../../Api';
import PhotoContent from '../Photo/PhotoContent';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../store/ui';
import { fetchPhoto } from '../../store/photo';
import UserPhotoEdit from '../User/UserPhotoEdit';

const FeedModalEdit = () => {

  const [modalPhoto, setModalPhoto] = React.useState(true);
  //const { loading, error, data } = useSelector((state) => state.photo);
  //const dispatch = useDispatch();

  //React.useEffect(() => {
    //dispatch(fetchPhoto(photo.id));
  //}, [dispatch, photo]);

  function handleOutsideClick(event) {
    console.log('target');
    console.log(event.target);

    console.log('onclose');
    console.log(event.currentTarget);    
    if (event.target === event.currentTarget) setModalPhoto(null);
   // if (event.target === event.currentTarget) dispatch(closeModal());
  }

  return (
    <div>
    { modalPhoto && <div className={styles.outside} onClick={handleOutsideClick}> 
      <div className={styles.modal}>
        <UserPhotoEdit onClose={handleOutsideClick} />
      </div>
    </div>}
    </div>
  );
};

export default FeedModalEdit;
