import axios from "axios"
import { useState } from "react"
import React from 'react'
import { Container, Row, Col, Form, Button, ProgressBar, Alert } from "react-bootstrap"
//import axiosInstance from "./Axios"

const UploadApp = (props) => {

  const [selectedFiles, setSelectedFiles] = useState([])
  const [progress, setProgress] = useState()
  const [error, setError] = useState()
  
  const submitHandler = (e) => {
    e.preventDefault() //prevent the form from submitting

    props.Titulo('Enviando...');

    let formData = new FormData()

    var arq = selectedFiles;

    for(var i=0; i < arq.length; i++) {
        formData.append('photos[]',arq[i],arq[i].name);
        //console.log (arq[i]);
    }

    formData.append('idplat',props.idPlat);

    //formData.append("photos[]", selectedFiles[0])
    //formData.append('idplat',props.idPlat);

    //Clear the error message
    setError("")

    var fecha = props;

    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

    axios ({
        method: 'POST',
        url: `${props.UrlSite}api/files/upload/${props.ProductID}`,
        data: formData,
        headers: {}, 
        onUploadProgress: data => {
            setProgress(Math.round((100 * data.loaded) / data.total))
          },
        transformRequest: [(data, headers) => {
            return data;
        }]
    }).then(function (response) {

        if (arq.length === 0) {
          setError("Selecione um arquivo antes de enviar!")
        }
        //console.log('Success: ', response);
        fecha.Atualizar();
        fecha.Last();
        fecha.Titulo('Banco de Arquivos');
        document.getElementById("photos[]").value = null;
        setProgress('');

    }).catch(error => {
      const { code } = error?.response?.data
      switch (code) {
        case "FILE_MISSING":
          setError("Selecione um arquivo antes de enviar!")
          break
        case "413":
          setError("Arquivo grande demais!")
          break
        default:
          setError("Ocorreu um erro: talvez arquivo grande demais ");
          break
      }
    })
   };


    /*
    axiosInstance.post("https://blautoparts.com.br/loja/api/productplat/upload/110701", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: data => {
        //Set the progress value to show the progress bar
        setProgress(Math.round((100 * data.loaded) / data.total))
      },
    })
    .catch(error => {
        const { code } = error?.response?.data
        switch (code) {
          case "FILE_MISSING":
            setError("Please select a file before uploading!")
            break
          default:
            setError("Sorry! Something went wrong. Please try again later")
            break
        }
      })
  }
  */



  return (
    <Container>
      <Row>
          <Col lg={{ span: 9, offset: 3 }}>
          <Form
            method="post"
            encType="multipart/form-data"
            onSubmit={submitHandler}
            className="form-inline"
          >
            <Col lg={{ span: 6 }}>
            <Form.Group>
              <Form.File
                id="photos[]"
                name="photos[]"
                className="my-3"
                onChange={e => {
                  setSelectedFiles(e.target.files)
                }}
                multiple />
            </Form.Group>
            </Col>
            <Col lg={{ span: 6 }}>
            <Form.Group>
              <Button variant="primary" type="submit">
                Enviar
              </Button>
            </Form.Group>
            </Col>
          </Form>
        <Col lg={{ span: 6, offset: 3 }}>
        {error && <Alert variant="danger">{error}</Alert>}
            {!error && progress && (
              <ProgressBar now={progress} label={`${progress}%`} />
            )}
        </Col>
        </Col>
      </Row>
    </Container>
  )
}

export default UploadApp