import React from 'react';
import UserHeader from './UserHeader';
import { Routes, Route } from 'react-router-dom';
import Feed from '../Feed/Feed';
import UserPhotoPost from './UserPhotoPost';
import UserPerfilPost from './UserPerfilPost';
import UserStats from './UserStats';
import NotFound from '../NotFound';
//import Header from '../../Components/Header';
import Head from '../Helper/Head';
import Panel from '../Panel';
import { useSelector } from 'react-redux';

const User = () => {
  //const { data } = React.useContext(UserContext);
  const { data } = useSelector((state) => state.user);

  React.useEffect(() => {

    document.querySelector("body").style.paddingTop="4rem";
    const header = document.getElementById('header');
    header.style.display = 'block'; 
    const footer = document.getElementById('footer');
    footer.style.display = 'block'; 

  }, []);


  return (
    <section className="container">
      <Head title="Minha Conta" />
      <UserHeader />
      <Routes>
        <Route path="/" element={<Feed user={data?.id} />} />
        <Route path="perfil" element={<UserPerfilPost />} />
        <Route path="post" element={<UserPhotoPost />} />
        <Route path="ideas" element={<Panel />} />
        <Route path="stats" element={<UserStats />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </section>
  );
};

export default User;
