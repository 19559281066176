import React from 'react';
import styles from './Header.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as Dogs } from '../Assets/logo.svg';
import { UserContext } from '../UserContext';
import useForm from '../Hooks/useForm';
import Pesquisar from './Forms/pesquisar';
import { appSocket } from '../config/App'

const Header = () => {
  //const { data } = React.useContext(UserContext);
  const { data } = useSelector((state) => state.user);


  React.useEffect(() => {

    //enviarNotificacao();

  }, []);

  function enviarNotificacao() {

    if (data) {
    appSocket.emit("login", {email: data.email, password: 'senha'}, function(data){
    console.log(data);

      if (data != null) {

        appSocket.emit("entrar", data.name, function(valido){
          console.log ('valido' + valido);
          if(valido){
    
          }else{
            alert("name já utilizado nesta sala");
          }
        });

      }
  
    });
  }
  }


  return (
    <header id="header" className={styles.header}>
      <nav className={`${styles.nav} container`}>
        <Link className={styles.logo} to="/" aria-label="MyBrain - Home">
          <Dogs style={{ maxWidth: '80px', maxHeight: '80px'}}/>
        </Link>
        <Pesquisar/>
        {data ? (
          <Link className={styles.login} to="/count">
            {data.username}
          </Link>
        ) : (
          <Link className={styles.login} to="/login">
            Entrar
          </Link>
      )}
      </nav>
    </header>
  );
};

export default Header;
