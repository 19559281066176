import React from 'react';
import Feed from './Feed/Feed';
import FeedBig from './Feed/FeedBig';
import Head from './Helper/Head';
import Ideas from './Ideas/Ideas';
import News from './News/News';

const Inicial = (props) => {

  React.useEffect(() => {

    const link = document.querySelector('link[rel="monetization"]')
        link.addEventListener("monetization", () => {
            console.log('is on...')
    });

    document.querySelector("body").style.paddingTop="4rem";
    const header = document.getElementById('header');
    header.style.display = 'block'; 
    const footer = document.getElementById('footer');
    footer.style.display = 'block'; 

  }, []);


  return (
    <section className="container-fluid mainContainer" style={{backgroundColor : '#374046', paddingBottom: '20px'}}>
      <Head
        title="MyBrain"
        description="MyBrain, onde as ideias se completam."
      />

      <News/>
      <div className="grid">
      <Ideas area_id={props.area_id} />
      </div>

    </section>
  );
};

export default Inicial;
